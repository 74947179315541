import React, {useState, useCallback, useEffect} from 'react';
import Numpad from '../Numpad/Numpad'
import './Login.scss';
import useEventListener from '../EventListener/EventListener'
import { toast } from "react-toastify";

export default function Login(props){

    const [typedValue,setTypedValue] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); 



    const checkPin = async () => {
        try {
            const response = await fetch('/api/employee-sign-in-db/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code: typedValue })
            });

            const data = await response.json();
            console.log('login response data', data);

            if (data.id && data.token) {
                toast.success(`Logged in as ${data.firstname}`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                sessionStorage.setItem('token', data.token);  // Store the token in sessionStorage
                props.setUser({ 
                    name: data.firstname,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    middlename: data.middlename,
                    nickname: data.nickname,
                    id: data.id,
                    pin: typedValue, 
                    admin: data.admin,
                    manager: data.manager,
                    front: data.front,
                    kitchen: data.kitchen
                    });
                setTypedValue('');
                setErrorMessage(''); // Clear any previous error messages
                props.restoreDirectoryAccess();
            } else {
                setTypedValue('');
                setErrorMessage(data.error || 'An error occurred.'); // Set error message from API or a default one
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    //check if the pin code is a valid one
    useEffect(()=>{
        if(typedValue.length>=4){
            checkPin();
        }
    },[typedValue.length])




    //change pin code typed when keyboard input
    const handler = useCallback(
        (event) => {
            let num = event.key;
            if(num>=0 && num <=9){
                setTypedValue(prevValue=>prevValue+num)
            } else if (num==='Backspace'){
                setTypedValue(prevValue=>prevValue.slice(0, -1))
            } else if (num==='Escape'){
                setTypedValue('');
            }
        },
        [setTypedValue]
    );
    
    useEventListener('keyup',handler)


    //check if theres a code passed in from the url
    if(window.location.href.includes('?code=')){
        var val = window.location.href.split('?code=')[1].split('?')[0];
        var newUrl = window.location.href.split('?code=')[0];
        console.log('new url: ',newUrl)
        window.history.pushState({}, null, newUrl)
        setTypedValue(val)
    }
    
    return(
        <div className="loginwindow">
            <div className="logintitle">
                <h1>{typedValue}</h1>
                {errorMessage && <h6 className="error-message">{errorMessage}</h6>}
            </div>
            <div className="loginpad">
                <div className="numpadwrapper">
                    <Numpad setTypedValue={setTypedValue}/>
                </div>
            </div>
        </div>
    )



}