import React, { MouseEvent, useState, useEffect, useRef, useMemo } from "react";
import "./ButtonGrid.scss";
import * as Types from "../POSWindow/POSTypes";
import defaultphoto from './default_photo.png'
import nophoto from '../POSWindow/no_photo.png'
import sounds from '../POSWindow/sounds/sounds'


const bloop1 = new Audio(sounds.bloop1) as HTMLAudioElement;
const bloop2 = new Audio(sounds.bloop2) as HTMLAudioElement;
const bloop3 = new Audio(sounds.bloop3) as HTMLAudioElement;
const pop1 = new Audio(sounds.pop1) as HTMLAudioElement;
const pop7 = new Audio(sounds.pop7) as HTMLAudioElement;
const block1 = new Audio(sounds.block1) as HTMLAudioElement;

interface ButtonGridProps {
	thisOrderItems: Types.ExtendedLineItem[];
	setThisOrderItems: (newOrder: Types.ExtendedLineItem[]) => void;
	typedValue: string;
	setTypedValue: (value: string) => void;
	orderId: number;
	members: Array<Types.Member>;
	setMembers(members: Types.Member[])
	orderSearchResults: Array<Types.Order>;
	setOrder: (order: Types.Order) => void;
	searchMembers: (term: string | number, force?: boolean) => void;
	clearOrder: (maintain?: boolean, openDrawer?: boolean) => void;
	subs: Types.Subscription[];
	subId: number[] | string[] | null;
	giftCards: Types.GiftCard[];
	orderNumber: number;
	isBFF: boolean;
	scannedItem: Types.ExtendedLineItem | null;
	setScannedItem: (scannedItem: Types.ExtendedLineItem | null) => void;
	triggerReset: number;
	isDelete: boolean;
	setIsDelete: (value: boolean) => void;
	currentTab: number;
	setCurrentTab: (value: number) => void;
}

const ButtonGrid = ({
	thisOrderItems,
	setThisOrderItems,
	typedValue,
	setTypedValue,
	orderId,
	members,
	orderSearchResults,
	setOrder,
	searchMembers,
	clearOrder,
	subs,
	subId,
	giftCards,
	setMembers,
	orderNumber,
	isBFF,
	scannedItem,
	setScannedItem,
	triggerReset,
	isDelete,
	setIsDelete,
	currentTab,
	setCurrentTab
}: ButtonGridProps) => {
	const [buttonlist, setButtonlist] = useState<Types.ExtendedLineItem[]>([]);
	const [numOfMods, setNumOfMods] = useState(0);
	const [modClass, setModClass] = useState<number | undefined>(0);
	const [parentModClass, setParentModClass] = useState<number | undefined>(0);
	const [modifyingItemIndex, setModifyingItemIndex] = useState<number | null>(
		null
	);
	const [triggerMembers, setTriggerMembers] = useState(0);
	const [triggerOrderSearch, setTriggerOrderSearch] = useState(0);
	const [reqMods, setReqMods] = useState<number[]>([]);
	const [optMods, setOptMods] = useState<number[]>([]);
	const [allMods, setAllMods] = useState<number[]>([]);
	const [modItems, setModItems] = useState<Types.ExtendedLineItem[]>([]);
	const [parentMods, setParentMods] = useState<Types.ExtendedLineItem[]>([]);
	const [inputValues, setInputValues] = useState({});
	//if (!buttonlist) { setButtonlist(admission) }
	const [buttons, setButtons] = useState([]);
	//let buttons = [];
	//const buttonlist: LineItem[] = admission;
	const buttonarea: string[] = [];
	const [isFetchingMods, setIsFetchingMods] = useState(false);
	const [selectedMember, setSelectedMember] = useState<Types.Member | null>(
		null
	);
	const [showPhotoUploadForm, setShowPhotoUploadForm] = useState(false);
	const [capturedImagePreview, setCapturedImagePreview] = useState<string | ArrayBuffer | null>(null);
	const videoRef: any = useRef(null);
	const [capturedImage, setCapturedImage] = useState<string | ArrayBuffer | null | Blob>(null);
	const [showCameraModal, setShowCameraModal] = useState(false);
	const [editedMember, setEditedMember] = useState<Types.ExtendedLineItem | null>(null);

	const [isEditMember, setIsEditMember] = useState(false);
	const [isAddBarcode, setIsAddBarcode] = useState(false);
	const [triggerGCs, setTriggerGCs] = useState(0);
	const [triggerButtonBuild, setTriggerButtonBuild] = useState(0);
	const [tabData, setTabData] = useState({});
	const [modData, setModData] = useState({});
	const [creatingForm, setCreatingForm] = useState(false);
	const [showBarcodeModal, setShowBarcodeModal] = useState(false);
	const [selectedPLUItem, setSelectedPLUItem] = useState<Types.ExtendedLineItem | null>(null);
	const [barcode, setBarcode] = useState("");
	const [contextMenu, setContextMenu] = useState<{ visible: boolean, x: number, y: number, currentItem: Types.ExtendedLineItem | null }>({ visible: false, x: 0, y: 0, currentItem: null });
	const [modUpdateTrigger, setModUpdateTrigger] = useState(false);
	const barcodeBufferRef = useRef('');
	const timeoutRef = useRef(null);
	const [bufferedBarcode, setBufferedBarcode] = useState("");


	// Add event listeners to track Delete/Backspace keydown and keyup
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Delete' || event.key === 'Backspace') {
				setIsDelete(true);  // Set isDelete to true when either key is held down
			}
		};

		const handleKeyUp = (event) => {
			if (event.key === 'Delete' || event.key === 'Backspace') {
				setIsDelete(false);  // Reset isDelete when the keys are released
			}
		};

		// Attach event listeners to the window object
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);

		// Cleanup function to remove event listeners
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, []);


	useEffect(() => {
		console.log('isDelete', isDelete);
	}, [isDelete])

	const processBarcode = () => {
		const barcode = barcodeBufferRef.current;
		setBufferedBarcode(barcode);
		//console.log('Processing barcode:', barcode);
		// Process the barcode
		//barcodeBufferRef.current = ''; // Reset buffer after processing
	};

	useEffect(() => {
		console.log('new barcode', bufferedBarcode);
		//wait for 1 second then clear the barcode
		if (bufferedBarcode) {
			setTimeout(() => {
				setBufferedBarcode("");
				barcodeBufferRef.current = '';
			}, 500);
		}
	}, [bufferedBarcode])


	useEffect(() => {
		const handleKeydown = (event) => {
			// Check if the keydown event is from the barcode scanner (or a rapid input source)
			if (event.key?.length === 1) { // assuming barcode characters are single characters like numbers or letters
				barcodeBufferRef.current += event.key;

				clearTimeout(timeoutRef.current);
				timeoutRef.current = setTimeout(processBarcode, 100); // adjust delay as needed
			}
		};

		document.addEventListener('keydown', handleKeydown);
		return () => {
			document.removeEventListener('keydown', handleKeydown);
			clearTimeout(timeoutRef.current);
		};
	}, []);

	const handleRightClick = (event, item) => {
		event.preventDefault(); // Prevent the default context menu
		console.log('right click on', item);
		setContextMenu({
			visible: true,
			x: event.clientX,
			y: event.clientY,
			currentItem: item,
		});
	};

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (contextMenu.visible && !event.target.closest('.context-menu')) {
				setContextMenu({ visible: false, x: 0, y: 0, currentItem: null });
			}
		};

		document.addEventListener('click', handleOutsideClick);
		return () => document.removeEventListener('click', handleOutsideClick);
	}, [contextMenu.visible]);

	useEffect(() => {
		if (scannedItem) {
			console.log("scanned item", scannedItem)
			addItem(scannedItem)
			setScannedItem(null)
		}
	}, [scannedItem])


	const renderPhotoUploadForm = () => {
		useEffect(() => {
			if (capturedImage) {
				const reader = new FileReader();
				reader.onloadend = () => {
					if (typeof reader.result === "string") {
						setCapturedImagePreview(reader.result);
					} else {
						console.error("Expected a string for capturedImagePreview");
					}
				};
				reader.readAsDataURL(capturedImage as Blob); // Make sure capturedImage is a Blob
			}
		}, [capturedImage]);

		const CameraModal = ({ show }) => {
			const [isLoading, setIsLoading] = useState(true); // Add loading state

			if (!show || capturedImage) {
				return;
				//setShowCameraModal(false)
			}

			const capturePhoto = () => {
				//setShowCameraModal(false)
				const canvas = document.createElement("canvas");
				const video: HTMLVideoElement | null = videoRef.current;
				if (canvas && video) {
					canvas.width = video.videoWidth;
					canvas.height = video.videoHeight;
					canvas.getContext("2d")?.drawImage(video, 0, 0);
					canvas.toBlob((blob) => {
						blob && setCapturedImage(blob);
					}, "image/jpeg");
				}
			};

			useEffect(() => {
				const startVideoStream = async () => {
					try {
						const stream = await navigator.mediaDevices.getUserMedia({
							video: true,
						});
						if (videoRef.current) {
							videoRef.current.srcObject = stream;
							await videoRef.current.play();
							setIsLoading(false); // Set loading to false when stream starts
						}
					} catch (error) {
						console.error("Error starting video stream:", error);
						setIsLoading(false); // Ensure loading is set to false even if there is an error
					}
				};


				if (show) {
					startVideoStream();
				}
			}, [show]);

			return (
				<div className="camera-modal" style={{ display: showCameraModal ? "block" : "none" }}>
					{isLoading && <div className="loading-box">Loading Camera...</div>}
					<video ref={videoRef} className={`video-stream ${isLoading ? 'hidden' : ''}`}></video>
					<button onClick={capturePhoto} className="capture-button">
						Capture Photo
					</button>
				</div>
			);

		};

		if (!showPhotoUploadForm || !selectedMember) return null;
		const stopVideoStream = () => {
			if (videoRef.current && videoRef.current.srcObject) {
				const stream = videoRef.current.srcObject;
				const tracks = stream.getTracks();

				tracks.forEach((track) => {
					track.stop();
				});

				videoRef.current.srcObject = null;
				//console.log("Stream stopped");
			}
		};

		const handlePhotoUpload = async (e = null) => {
			if (e) e.preventDefault();
			if (capturedImage) {
				// Create a FormData object and append the captured image
				const formData = new FormData();

				// Assuming capturedImage is of type string | ArrayBuffer | Blob
				if (capturedImage instanceof Blob) {
					formData.append("image", capturedImage);
				} else {
					console.error("capturedImage is not a Blob");
				}


				formData.append(
					"membership_number",
					selectedMember.membership_number.toString()
				);

				// Make the POST request to your API
				try {
					const response = await fetch("/api/upload-member-photo", {
						method: "POST",
						body: formData,
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					});
					// Handle the response
					if (response.ok) {
						const responseData = await response.json();
						// Reset the form or navigate as necessary
						setShowPhotoUploadForm(false);
						setCapturedImagePreview(null);
						setCapturedImage(null);

						// Convert the Blob to a data URL and update the member's photo
						const reader = new FileReader();
						reader.onloadend = () => {
							if (typeof reader.result === "string") {
								setCapturedImagePreview(reader.result);
							} else {
								console.error("Expected a string for capturedImagePreview");
							}
							// Update the members array with the new photo data URL
							const newMembers: Types.Member[] = members.map(member =>
								member.membership_number === selectedMember.membership_number
									? { ...member, photo: reader.result }
									: member
							);

							// Update the state with the new members array
							setMembers(newMembers);
							setTriggerButtonBuild(prev => prev + 1);
						};
						reader.readAsDataURL(capturedImage as Blob); // Make sure capturedImage is a Blob
					} else {
						console.error("Failed to upload photo");
					}
				} catch (error) {
					console.error("Error uploading photo:", error);
				}
			}
		};


		if (capturedImage == null && showCameraModal == false) {
			setShowCameraModal(true);
		}
		if (capturedImage && showCameraModal == true) {
			setShowCameraModal(false);
			stopVideoStream();
			handlePhotoUpload();
		}

		const hideForm = () => {
			stopVideoStream();
			setShowPhotoUploadForm(false);
			setCapturedImagePreview(null);
			setCapturedImage(null);
			setShowCameraModal(false);
		};
		return (
			<form className="photo-upload-form modal" onSubmit={handlePhotoUpload}>
				<CameraModal show={showCameraModal} />
				{typeof capturedImagePreview === 'string' && (
					<img src={capturedImagePreview} alt="Captured" height="50%" />
				)}
				<button key="cancel" className="cancel" onClick={hideForm}>
					Cancel
				</button>
				<br />
				<br />
				<br />
			</form>
		);
	};

	useEffect(() => {
		//setCurrentTab(1);
		setNumOfMods(0);
		console.log("triggerReset: ", triggerReset);
		setModItems([]);
		setParentModClass(null)
		setModUpdateTrigger(prev => !prev);
		setTriggerButtonBuild(prev => prev + 1);
		setAllMods([]);
		setModClass(null);
		setNumOfMods(0);
	}, [triggerReset])

	useEffect(() => {
		console.log('members triggered!')
		if (members.length > 0 && currentTab === 7) {
			//setCurrentTab(7);

			const memberButtons = members.map((member) => {
				const visitsFromVisitsArray = member.visits_array
					? member.visits_array.filter(visit => {
						const visitDate = new Date(visit);
						const today = new Date();
						return visitDate.toDateString() === today.toDateString();
					}).length
					: 0;
				const visitsFromOrderItems = thisOrderItems.reduce((count, item) => {
					return count + (item.membership_number === member.membership_number ? 1 : 0);
				}, 0);
				const totalVisitsToday = visitsFromVisitsArray + visitsFromOrderItems;


				let sub: Types.Subscription;
				let valid_until = member.valid_until
					? new Date(member.valid_until)
					: member.due_date
						? new Date(member.due_date)
						: new Date("2022-12-31=");
				let signup_date = new Date(member.signup_date) || null;
				let payments_remaining = null;
				let payment_amount = null;
				let edit_url = null;
				let next_payment = null;
				let valid_starting = member.signup_date ? new Date(member.signup_date) : null;
				let total_paid;
				if (member.sub_id && subs) {
					total_paid = 0;
					sub = subs.find((sub) => Number(sub.id) === Number(member.sub_id));
					const plan = sub?.items.map(item => {
						for (const property of item.properties) {
							if (property.key === "_seal_selling_plan_id") {
								if (property.value == member._seal_selling_plan_id) {
									return item.properties
								}
							}
						}
					}).filter(value => value); // Filter out the null values

					//console.log('plan! ',plan)
					if (sub) {
						console.log(`Sub for ${member.name} is`, sub);
						signup_date = new Date(sub.order_placed);
						valid_starting = valid_starting ? valid_starting : new Date(sub.order_placed);
						payment_amount = (sub.total_value * 1.0825).toFixed(2);
						edit_url = sub.edit_url;

						if (plan.length > 0 && plan[0].length > 0) {
							//console.log(member.name,'plan is',plan,'?')
							for (const property of plan[0]) {
								if (property.key === "_seal_selling_plan_name") {
									if (property.value.includes("January 1")) {
										const nextYear = signup_date.getFullYear() + 1;
										valid_starting = new Date(nextYear, 0, 1);
									}
								}
							}
						} else if (sub?.items[0]?.properties) {
							for (const property of sub.items[0].properties) {
								if (property.key === "_seal_selling_plan_name") {
									if (property.value.includes("January 1")) {
										const nextYear = signup_date.getFullYear() + 1;
										valid_starting = new Date(nextYear, 0, 1);
									}
								}
							}
						}

						const successCount =
							sub.billing_attempts.filter(
								(attempt) => attempt.status === "completed"
							).length + 1;

						if (sub.billing_min_cycles > 0) {
							payments_remaining = sub.billing_min_cycles - successCount;
						}

						const lastSuccess: Types.BillingAttempt = [...sub.billing_attempts] // Copy the array
							.reverse()
							.find((attempt) => {
								return attempt.status == "completed";
							});

						let lastPayment = [...sub.billing_attempts] // Copy the array
							.reverse()
							.find((attempt) => {
								return attempt.status != "" && attempt.status != "completed";
							});
						if (!lastPayment) {
							lastPayment = [...sub.billing_attempts] // Copy the array
								.find((attempt) => {
									return attempt.status == "";
								});
						}
						next_payment = lastPayment ? new Date(lastPayment?.date) : null;

						const successDate = lastSuccess
							? new Date(lastSuccess?.date)
							: signup_date;
						if (sub.billing_interval.includes("month")) {
							successDate.setMonth(successDate.getMonth() + 1);
						} else if (sub.billing_interval.includes("year")) {
							successDate.setFullYear(successDate.getFullYear() + 1);
						}
						if (successDate > valid_until) {
							valid_until = successDate;
						}

						//find total price paid here:
						const subscriptionStartDate = valid_starting;
						const serviceStartDate = new Date('2023-04-28');
						console.log(subscriptionStartDate, serviceStartDate)
						// Check if subscription started before the service start date
						if (subscriptionStartDate < serviceStartDate) {

							const firstPaymentAmount = 35.99; // First payment at higher rate
							const monthsBeforeService = (serviceStartDate.getFullYear() - subscriptionStartDate.getFullYear()) * 12 + serviceStartDate.getMonth() - subscriptionStartDate.getMonth();
							console.log("mbs:", monthsBeforeService)
							// Assuming the regular payment amount is the final_amount in items[0] if no cycle discounts are present
							const regularPaymentAmount = sub.items[0]?.final_price;

							// Calculate total for payments made before the service start date
							// First payment at the higher rate, rest at the regular rate
							if (monthsBeforeService > 0) {
								total_paid += firstPaymentAmount; // Add first payment
								console.log(`total paid for ${member.name}: `, total_paid)
								if (monthsBeforeService > 1) {
									total_paid += (monthsBeforeService - 1) * regularPaymentAmount; // Add subsequent payments
									console.log(`total paid for ${member.name}: `, total_paid)
								}
							}
							console.log(`total paid for ${member.name}: `, total_paid)
						}
						console.log(`total paid for ${member.name} taking into account payments before service: `, total_paid)
						const cycleDiscounts = sub.items[0]?.cycle_discounts;
						const completedBillingAttempts = sub.billing_attempts.filter(attempt => attempt.status === "completed").length + 1;
						console.log(`cycle discounts for ${member.name}: `, cycleDiscounts)
						console.log(`completed billing attempts for ${member.name}: `, completedBillingAttempts)
						if (cycleDiscounts && cycleDiscounts.length > 0) {
							let remainingBillingAttempts = completedBillingAttempts;

							for (let i = 0; i < cycleDiscounts.length; i++) {
								const currentDiscount = cycleDiscounts[i];
								const nextDiscount = cycleDiscounts[i + 1];
								const currentPrice = parseFloat(currentDiscount.computed_price);

								let cyclesAtCurrentPrice = 1; // Default to 1 cycle if no next discount
								if (nextDiscount) {
									// If there's a next discount, calculate cycles at current price based on the difference in "after_cycle"
									cyclesAtCurrentPrice = nextDiscount.after_cycle - currentDiscount.after_cycle;
								} else if (i === cycleDiscounts.length - 1) {
									// For the last discount entry, use all remaining billing attempts
									cyclesAtCurrentPrice = remainingBillingAttempts;
								}
								console.log(`cycles at current price of ${currentPrice} for ${member.name} with remaining billing attempts ${remainingBillingAttempts}: `, cyclesAtCurrentPrice)
								// Calculate total for current discount segment, not exceeding the remaining billing attempts
								const cyclesToCalculate = Math.min(cyclesAtCurrentPrice, remainingBillingAttempts);
								console.log(`total paid for ${member.name}: `, total_paid)
								total_paid += (cyclesToCalculate * currentPrice);
								console.log(`total paid for ${member.name}: `, total_paid)
								// Subtract the calculated cycles from the remaining billing attempts
								remainingBillingAttempts -= cyclesToCalculate;

								// Break if no more billing attempts left to calculate
								if (remainingBillingAttempts <= 0) break;
							}
						} else if (completedBillingAttempts) {
							// If no cycle discounts, use the final_amount for each completed billing attempt
							//console.log(`total paid for ${member.name}: `,total_paid)
							//console.log('completed attempts: ',completedBillingAttempts)
							total_paid += (completedBillingAttempts * sub.items[0]?.final_price);
							console.log(`total paid for ${member.name}: `, total_paid)
						} else {
							total_paid += sub.items[0]?.final_price;
							console.log(`total paid for ${member.name}: `, total_paid)
						}
						//console.log(`total paid for ${member.name}: `,total_paid)
						total_paid = Math.round((total_paid) * 100) / 100;
						console.log(`total paid for ${member.name}: `, total_paid)


					}
				}
				return {
					name: member.name,
					title: `${member.membership_type} Check-In`,
					attendance_category: 'Member',
					calendar_category: 'Member',
					barcode: member.barcode || null,
					price: 0,
					quantity: 1,
					photo: member.photo || defaultphoto,
					hasPhoto: member.photo == nophoto ? false : true,
					membership_type: member.membership_type,
					membership_number: member.membership_number,
					status: member.status || null,
					due_date: member.due_date || null,
					next_payment: next_payment,
					valid_until: valid_until,
					max_quantity: 1,
					sub_id: member.sub_id,
					sub: sub,
					dob: new Date(member.dob),
					signup_date: signup_date,
					payments_remaining: payments_remaining || null,
					payment_amount: payment_amount,
					edit_url: edit_url,
					valid_starting: valid_starting,
					properties: [
						{
							name: "membership_number",
							value: member.membership_number.toString(),
						},
						{
							name: "name",
							value: member.name,
						},
					],
					function: "checkIn",
					...(member.visits_array?.length ?
						{ last_visit: new Date(member.visits_array[0]) }
						: {}
					),
					...(member.visits_array?.length ?
						{ visits: member.visits_array?.length }
						: {}),
					visitsToday: totalVisitsToday,
					totalPaid: total_paid,
					alert: member.alert || null
				};
			});
			setButtonlist(memberButtons);
		} else if (currentTab == 7) setCurrentTab(1);
	}, [members, subs, triggerMembers, thisOrderItems]); //trigger creation of member buttons when loaded members change

	useEffect(() => {
		if (giftCards.length > 0) {
			const gcButtons: Types.ExtendedLineItem[] = giftCards.map(
				(card: Types.GiftCard) => {
					return {
						title: "Gift Card",
						price: 0,
						quantity: 1,
						max_quantity: 1,
						properties: [
							{
								name: "Items",
								value: card.items,
							},
							{
								name: "Card Number",
								value: card.card_number,
							},
						],
						function: "redeemGC",
						attendance_category: "Gift Card",
						valid_until: card.expiration || new Date("12/31/3000"),
						redeemed: card.redeem_timestamp || null,
					};
				}
			);
			//console.log("gcbuttons: ", gcButtons);
			if (giftCards.length > 0 && currentTab == 9) {
				//setCurrentTab(9);
				setButtonlist(gcButtons);
			}
		} else if (currentTab == 9) setCurrentTab(1); //if no giftCards return to tab 1
	}, [giftCards, triggerGCs]); //trigger creation of gift cards

	useEffect(() => {
		if (orderSearchResults.length > 0 && currentTab == 6) {
			const orderButtons = orderSearchResults.map((order: Types.Order) => {
				const items = order.line_items || [];
				const itemsList = items.map((item) => {
					return `${item.quantity}x${item.title}`
				})
				let title = ' '
				if (order.customer) {
					title = order.customer?.first_name + ' ' + order.customer?.last_name
				} else if (order.order_number) {
					title = `Order ${order.order_number}`
				}
				return ({
					title: title,
					subtitle: itemsList.toString(),
					barcode: order.id,
					function: "searchOrders",
					order: order,
					price: 0,
					quantity: 0,
					date: new Date(order.created_at).toLocaleDateString(),
					status: order.cancelled_at ? 'Cancelled' : order.fulfillment_status
				})
			});
			setButtonlist(orderButtons);
		}
	}, [orderSearchResults, triggerOrderSearch]); //trigger creation of order search buttons

	useEffect(() => {
		const fetchMods = async (modClassToFetch) => {
			try {
				const response = await fetch(`/api/get-pos-mods/${modClassToFetch}`, {
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
				});
				const posTabs = await response.json();
				return posTabs.length > 0 ? posTabs : [];
			} catch (error) {
				console.error("Failed to fetch mods:", error);
				return [];
			}
		};

		const mergeParentMods = (mods, parentMods) => {
			const merged = [...mods];
			let parentIndex = 0;
			for (let i = 0; i < merged.length; i++) {
				if (!merged[i].title && parentMods[parentIndex]) {
					merged[i] = parentMods[parentIndex];
					parentIndex++;
				}
				if (parentIndex >= parentMods.length) break;
			}
			return merged;
		};

		const handleModsFetch = async () => {
			let parentMods = [];
			if (parentModClass && parentModClass > 0) {
				parentMods = await fetchMods(parentModClass);
				//for each of the parentMods, if the mod has a max_quantity then we need to check to see if it's alread on the last item of the buttonlist as a mod
				for (let i = 0; i < parentMods.length; i++) {
					console.log('parent mod: ', parentMods[i]);
					let modCount = 0;
					if (parentMods[i].max_quantity && buttonlist.length > 0) {
						const lastItem = thisOrderItems[thisOrderItems.length - 1];
						console.log('lastItem: ', lastItem);
						//check each mod on the lastItem to see if parentMods[i] is on it already
						for (let j = 0; j < lastItem?.properties?.length; j++) {
							console.log('lastItem.properties[j].value: ', lastItem.properties[j].value);
							console.log('parentMods[i].title: ', parentMods[i].title);
							if (lastItem.properties[j].value == parentMods[i].title) {
								modCount++;
							}
						}
						console.log('modCount: ', modCount);
						if (modCount >= parentMods[i].max_quantity) {
							parentMods[i] = null;
							console.log('removed a parent mod');
						}
					}
					if (parentMods[i].mod_class && parentMods[i].mod_class !== 255000) {
						if (Math.floor(parentMods[i].mod_class / 1000) !== modClass) {
							parentMods[i] = null;
						}
					}
				}
			}
			parentMods = parentMods.filter((mod) => mod !== null);
			console.log('parentModClass: ', parentModClass, 'parent mods: ', parentMods);

			let theseModItems = [...modItems]
			if (theseModItems) {
				//for each of the parentMods, if the mod has a max_quantity then we need to check to see if it's alread on the last item of the buttonlist as a mod
				for (let i = 0; i < theseModItems.length; i++) {
					console.log('mod item: ', theseModItems[i]);
					let modCount = 0;
					if (theseModItems[i].max_quantity && buttonlist.length > 0) {
						const lastItem = thisOrderItems[thisOrderItems.length - 1];
						console.log('lastItem: ', lastItem);
						//check each mod on the lastItem to see if parentMods[i] is on it already
						for (let j = 0; j < lastItem?.properties?.length; j++) {
							console.log('lastItem.properties[j].value: ', lastItem.properties[j].value);
							console.log('parentMods[i].title: ', theseModItems[i].title);
							if (lastItem.properties[j].value == theseModItems[i].title) {
								modCount++;
							}
						}
						console.log('modCount: ', modCount);
						if (modCount >= theseModItems[i].max_quantity) {
							//remove the mod from the array
							theseModItems[i] = null;
							console.log('removed a parent mod');
						}
					}
				}
			}
			//filter null items out of the array
			theseModItems = theseModItems.filter((item) => item !== null);


			if (modClass > 0 && (currentTab == 5 || numOfMods > 0)) {
				const mods = await fetchMods(modClass);
				mods.sort((a, b) => (a.sku > b.sku ? 1 : -1));
				setButtonlist(mergeParentMods(mergeParentMods(mods, parentMods), theseModItems));
			} else if (numOfMods > 0 && modClass == 0) {
				setCurrentTab(5);
				setNumOfMods(0);
			} else if (currentTab <= 4 || currentTab == 11) {
				if (tabData[currentTab]) {
					setButtonlist(mergeParentMods(mergeParentMods(tabData[currentTab], parentMods), theseModItems));
				} else {
					try {
						const response = await fetch(
							`/api/get-pos-by-tab/${currentTab}`,
							{
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									Authorization: `Bearer ${sessionStorage.getItem("token")}`,
								},
							}
						);
						const posButtons = await response.json();
						//setButtonlist(mergeParentMods(posButtons, parentMods));
						setButtonlist(mergeParentMods(mergeParentMods(posButtons, parentMods), theseModItems));
						setTabData(prev => ({ ...prev, [currentTab]: posButtons }));
					} catch (error) {
						console.error("Failed to fetch POS tabs:", error);
					}
				}
			} else if (currentTab == 5) {
				setCurrentTab(1);
			} else if (currentTab == 7) {
				setTriggerMembers((prev) => prev + 1);
			} else if (currentTab == 6) {
				setTriggerOrderSearch((prev) => prev + 1);
			} else if (currentTab == 8) {
				return;
			} else if (currentTab == 9) {
				setTriggerGCs((prev) => prev + 1);
			} else {
				setButtonlist([]);
				setNumOfMods(0);
			}
			if (currentTab != 8) {
				//setModItems([]);
				setInputValues({});
			}
			if (currentTab == 10) {
				console.log('we need default mods!');
				const mods = await fetchMods(255);
				//sort mods by title
				mods.sort((a, b) => a.title.localeCompare(b.title));
				setButtonlist(mods);
			}
		};

		handleModsFetch();
	}, [numOfMods, currentTab, modUpdateTrigger, parentModClass]); //fetch buttons on tab set


	useEffect(() => {
		setModUpdateTrigger((prev) => !prev);
	}, [modItems])

	// useEffect for initializing input values
	useEffect(() => {
		if (members.length == 1 && modItems.length && allMods && currentTab == 7) {
			const initialValues = {};
			allMods.forEach((mod) => {
				//console.log(mod);
				//console.log('changing allMods!')
				//console.log(modItems);
				const modItem = modItems.find((item) => item.mod === mod);
				//console.log(modItem);
				const inputKey = modItem ? modItem.title : mod;
				//console.log(modItem);
				if (modItem.mod_type == "date" && members[0]?.[inputKey]) {
					// Check if the type is 'date' and the value is not empty
					// Convert the input value to a Date object
					const date = new Date(members[0]?.[inputKey]);
					let formattedValue = members[0]?.[inputKey];
					// Format the date to YYYY-MM-DD, which is the format expected by HTML date inputs
					const year = date.getFullYear();
					const month = `0${date.getMonth() + 1}`.slice(-2); // Add leading 0 if needed
					const day = `0${date.getDate()}`.slice(-2); // Add leading 0 if needed

					formattedValue = `${year}-${month}-${day}`;
					initialValues[inputKey] = formattedValue || null;
				} else {
					initialValues[inputKey] = members[0]?.[inputKey] || null;
				}
			});

			setInputValues(initialValues);
		}
	}, [members, allMods, modItems]); // Only re-run when members, allMods, or modItems changes

	useEffect(() => {
		if (thisOrderItems.length > 0 && modItems.length > 0 && !creatingForm) {
			setCreatingForm(true);
			const formElements: any[] = [];
			console.log('creating form...', thisOrderItems, modItems, inputValues)
			const handleInputChange = (modID, value, type?) => {
				let formattedValue = value;

				if (type === "date") {
					// Split the input value to analyze its parts
					const dateParts = value.split('-');

					// Proceed only if all date parts are present
					if (dateParts.length === 3) {
						const year = dateParts[0];
						const month = dateParts[1];
						const day = dateParts[2];

						// Validate the year part more rigorously
						if (year.length === 4 && parseInt(year, 10) > 1900) {
							// Format month and day to ensure they are always two digits
							const formattedMonth = month.padStart(2, '0');
							const formattedDay = day.padStart(2, '0');
							formattedValue = `${year}-${formattedMonth}-${formattedDay}`;
						} else {
							// If the year part is not valid, consider how you want to handle this
							// For example, you might not update the state or you might set an error message
							return; // Exit without updating the state
						}
					} else {
						// If not all parts are present, don't attempt to format the date
						return; // Exit without updating the state
					}
				}

				// Update the state with the validated and potentially formatted date
				setInputValues((prev) => ({ ...prev, [modID]: formattedValue }));
			};



			const handleKeyDown = (e, inputIndex, modId) => {
				if (e.key === "Enter") {
					e.preventDefault();
					if (barcodeBufferRef.current !== "") {
						console.log('waiting for barcode');
						// Wait for the bufferedBarcode to be available
						setTimeout(() => {
							console.log('done waiting. value:', barcodeBufferRef.current);
							setInputValues((prev) => ({ ...prev, [modId]: barcodeBufferRef.current }));
						}, 100);
					}

					// Find the next input field and focus on it
					const nextInput = document.querySelectorAll(".modifier-form input")[inputIndex + 1] as HTMLElement;
					if (nextInput) {
						nextInput.focus();
					}
				}
			};

			allMods.forEach((mod, index) => {
				const filteredModItems = modItems.filter((item) => item.mod === mod);

				if (filteredModItems.length === 1 && filteredModItems[0].mod_type) {
					setCurrentTab(8);
					const modItem = filteredModItems[0];
					const inputKey = `${modItem.title}`; // Unique key for each input

					switch (modItem.mod_type) {
						case "string":
							formElements.push(
								<>
									{inputKey}
									<br />
									<input
										key={`${inputKey}-${index}`}
										type="text"
										value={inputValues[inputKey] || ""}
										onChange={(e) => {
											handleInputChange(inputKey, e.target.value)
										}
										}
										onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
										placeholder={`Enter ${modItem.title}`}
									/>
									<br />
									<br />
								</>
							);
							break;
						case "number":
							formElements.push(
								<>
									{inputKey}
									<br />
									<input
										key={`${inputKey}-${index}`}
										type="number"
										value={inputValues[inputKey] || 0}
										onChange={(e) =>
											handleInputChange(
												inputKey,
												e.target.value.replace(/^0+/g, "")
											)
										}
										onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
										placeholder={`Enter ${modItem.title}`}
									/>
									<br />
									<br />
								</>
							);
							break;
						case "date":
							formElements.push(
								<>
									{inputKey}
									<br />
									<input
										key={`${inputKey}-${index}`}
										type="date"
										value={inputValues[inputKey] || null}
										onChange={(e) =>
											handleInputChange(inputKey, e.target.value, "date")
										}
										onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
									/>
									<br />
									<br />
								</>
							);
							break;
						case "boolean":
							formElements.push(
								<>
									{inputKey}
									<br />
									<input
										key={`${inputKey}-${index}`}
										type="checkbox"
										checked={!!inputValues[inputKey]}
										onChange={
											(e) => handleInputChange(inputKey, e.target.checked, inputKey)
										}
									/>
									<br />
									<br />
								</>
							);
							break;
						// Add cases for other mod types as needed
					}
				}
			});
			let modifierFormButton;
			if (formElements.length > 0) {
				modifierFormButton = (
					<div
						key={`buttondiv-form`}
						id={"buttondiv-form"}
						className="button"
						style={{
							gridArea: `6 / 2 / span 60 / span 60`,
						}}
					>
						<form className="modifier-form" onSubmit={handleFormSubmit}>
							{formElements}
							<button type="submit" className="submit-mods-button">
								Apply
							</button>
						</form>
					</div>
				);
				setButtons([modifierFormButton]); // Add this form as a 'button' in your grid
			}
			setCreatingForm(false);
		}
	}, [modItems, inputValues]);//create mod form

	const handleFormSubmit = (e) => {
		e.preventDefault();
		// Retrieve the last added item
		const lastAddedItemIndex = thisOrderItems.length - 1;
		const lastAddedItem = thisOrderItems[lastAddedItemIndex];
		if (!lastAddedItem.properties) {
			lastAddedItem.properties = [];
		}
		// Apply modifiers from form input values
		Object.entries(inputValues).forEach(([modID, value]) => {
			if (value) {
				const modifier = modItems.find(
					(item) => item.title.toString() === modID
				);
				if (modifier) {
					lastAddedItem.properties.push({
						name: modifier.title,
						value: value.toString(),
					});
				}
			}
		});

		// Update the order items with the modified last item
		setThisOrderItems([
			...thisOrderItems.slice(0, lastAddedItemIndex),
			lastAddedItem,
		]);
		setCurrentTab(1);
		setTriggerButtonBuild(prev => prev + 1)
		setModItems([]);
		setInputValues({});
	};


	useEffect(() => {
		// Check if the currently modifying item is still in the cart
		const isModifyingItemInCart = modifyingItemIndex !== null && thisOrderItems[modifyingItemIndex] !== undefined;
		if (!isModifyingItemInCart) {
			// If the item has been removed, reset the form and related states
			setInputValues({});
			setModifyingItemIndex(null); // Or another logic to handle this situation
			setTriggerButtonBuild(prev => prev + 1);
			//setCurrentTab(1);
			// Reset any other states related to the modifier form as necessary
		}
	}, [thisOrderItems, modifyingItemIndex, setInputValues, setModifyingItemIndex]);//track mod form


	const editMember = (member) => {
		console.log("Editing member #", member.membership_number);
		// Format dob to YYYY-MM-DD
		const formattedMember = { ...member };
		if (formattedMember.dob) {
			const dobDate = new Date(formattedMember.dob);
			const year = dobDate.getFullYear();
			const month = String(dobDate.getMonth() + 1).padStart(2, '0');
			const day = String(dobDate.getDate()).padStart(2, '0');
			formattedMember.dobstring = `${year}-${month}-${day}`;
		}
		if (formattedMember.valid_until) {
			const date = new Date(formattedMember.valid_until);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			formattedMember.valid_until_string = `${year}-${month}-${day}`;
		}
		setEditedMember(formattedMember);
		setIsEditMember(true);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setEditedMember(prevState => ({ ...prevState, [name]: value }));
	};

	const handleSaveMember = (event) => {
		event.preventDefault();
		if (editedMember) {
			memberEditDetails({
				membership_number: editedMember.membership_number,
				dob: editedMember.dobstring,
				name: editedMember.name,
				membership_type: editedMember.membership_type,
				barcode: editedMember.barcode,
				sub_id: editedMember.sub_id,
				valid_until: editedMember.valid_until_string,
				alert: editedMember.alert
			});
			setIsEditMember(false);
		}
	};


	const memberEditDetails = (editMemberProperties) => {


		// Construct the body of the request
		const requestBody = JSON.stringify(editMemberProperties);
		console.log('request body: ', requestBody);
		// Fetch request to create a new member via the API
		fetch("/api/edit-member", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
			body: requestBody,
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Member edited:", data);
				//look in the members array, find the returned member, and update the member object for them
				const memberIndex = members.findIndex(member => member.membership_number === data.membership_number);
				console.log('member index: ', memberIndex, data.membership_number);
				if (memberIndex !== -1) {
					const updatedMembers = [...members];
					const updatedMember = { ...members[memberIndex], ...data };
					updatedMembers[memberIndex] = updatedMember;
					setMembers(updatedMembers);
					//setTriggerMembers(prev => prev + 1);
				}
				// Handle success - e.g., update UI or state
			})
			.catch((error) => {
				console.error("Error editing member:", error);
				// Handle error - e.g., show error message to user
			});
	}

	const addMemberBarcode = (e: React.FormEvent, member) => {
		e.preventDefault();
		setIsAddBarcode(true);
		setSelectedMember(member)
		console.log('editing memeber # ', member.membership_number);
	}

	useEffect(() => {
		const openPopupWindow = (url, windowName, windowFeatures) => {
			window.open(url, windowName, windowFeatures);
		};

		{
			const gridWidth = 60; // Total number of columns
			const tabRows = 5; // Rows reserved for tabs
			let buttonSize; // Size of each button in grid cells

			if (buttonlist.length <= 1) {
				buttonSize = gridWidth; // 60x60 (1x1)
			} else if (buttonlist.length <= 4) {
				buttonSize = gridWidth / 2; // 30x30 (2x2)
			} else if (buttonlist.length <= 9) {
				buttonSize = gridWidth / 3; // 20x20 (3x3)
			} else if (buttonlist.length <= 16) {
				buttonSize = gridWidth / 4; // 15x15 (4x4)
			} else if (buttonlist.length <= 25) {
				buttonSize = gridWidth / 5; // 12x12 (5x5)
			} else {
				buttonSize = gridWidth / 6; // 10x10 (6x6)
			}


			for (let j = 0; j < buttonlist.length; j++) {
				const rowStart =
					Math.floor(j / (gridWidth / buttonSize)) * buttonSize + 1 + tabRows;
				const colStart = (j % (gridWidth / buttonSize)) * buttonSize + 1;
				buttonarea[
					j
				] = `${rowStart} / ${colStart} / span ${buttonSize} / span ${buttonSize}`;
			}
		} //set button area settings based on number of items

		const newbuttons: JSX.Element[] = [];
		for (let j = 0; j < buttonlist?.length; j++) {
			const btn = buttonlist[j];
			//console.log("button: ", btn);
			const photo = btn.photo || "";
			const hasPhoto = btn.hasPhoto;

			newbuttons.push(
				<div
					key={"buttondiv-" + j}
					id={"buttondiv-" + j}
					onContextMenu={(e) => handleRightClick(e, btn)}

					className={`${btn.title ? "button" : "emptybutton"} ${new Date(btn?.valid_until) < new Date() ||
						new Date(btn?.valid_starting) > new Date() ||
						//new Date(btn?.last_visit).toLocaleDateString() == new Date().toLocaleDateString() ||
						btn?.redeemed
						? " inactive"
						: ""
						} ${isDelete ? " isDelete" : ""}
						${btn.status?.toLowerCase()}`}
					style={{
						gridArea: `${buttonarea[j]}`,
						backgroundImage: photo ? `url(${photo})` : "none",
						backgroundSize: photo ? "cover" : "", // or 'contain' based on your requirement
						backgroundPosition: "center"
					}}
					data-index={j} // Store the index of the button
				>
					{buttonlist.length != 1 && btn.membership_type && (
						<span
							className="edit-member"
							onClick={() => searchMembers(btn.membership_number)}
						>
							Details
						</span>
					)}
					{btn.membership_type && !btn.barcode && (
						<span
							className="add-barcode"
							onClick={(e) => {
								e.preventDefault(); // prevent the default behavior of the click event
								e.stopPropagation(); // Stop the event from bubbling up to parent elements
								addMemberBarcode(e, btn);
							}}
						>
							Add Barcode
						</span>
					)}
					{buttonlist.length == 1 && btn.membership_type && (
						<>
							<span
								className="edit-member"
								onClick={() => editMember(btn)}
							>
								Edit
							</span>
							{btn.sub_id &&
								<span
									className="all-members"
									onClick={() => searchMembers(btn.sub_id)}
								>
									View All
								</span>
							}
						</>
					)}
					{buttonlist.length == 1 && btn.membership_type && (
						<span
							className="new-photo"
							onClick={() => {
								setSelectedMember(btn);
								setShowPhotoUploadForm(true);
							}}
						>
							New Photo
						</span>
					)}
					<span
						key={"button-" + j}
						className={`buttontext 
              ${photo ? "image" : ""} 
              ${btn.membership_type?.includes("Premium") ? " premium" : ""}
              ${new Date(btn?.valid_until) < new Date() ||
								new Date(btn?.valid_starting) > new Date() ||
								//new Date(btn?.last_visit).toLocaleDateString() == new Date().toLocaleDateString() ||
								btn?.redeemed
								? " inactive"
								: ""
							}
              `}
						lang="en"
						id={"button-" + j}
					>
						{btn.name || btn.title || ""}
						{btn.redeemed ? (
							<p className="alert">
								<br />
								Already Redeemed on
								<br />
								{new Date(btn.redeemed).toLocaleString()}
							</p>
						) : (
							""
						)}
						{new Date(btn?.valid_until) < new Date() ? (
							btn.sub_id ?
								<p className="alert">
									{btn.membership_type}
									<br />
									Payment Due
									<br />
									{new Date(btn.valid_until).toLocaleString()}
								</p>
								:
								<p className="alert">
									{btn.membership_type}
									<br />
									<b>Expired</b>
								</p>
						) : (
							""
						)}
						{btn.visitsToday == 1 && isBFF && btn.membership_type.includes('Premium') ? (
							<p className="bffAlert">
								BFF Available
							</p>
						) : (
							""
						)}
						{btn.visitsToday > 0 ? (
							<p className="alert">
								Already Checked In Today
							</p>
						) : (
							""
						)}
						{btn.visitsToday == 2 ? (
							<p className="alert">
								BFF Already Checked In
							</p>
						) : (
							""
						)}
						{buttonlist.length == 1 && btn.membership_type && (
							<>
								{btn.membership_type && (
									<>
										<br />
										{btn.membership_type}
									</>
								)}
								{btn.valid_starting && (
									<>
										<br />
										Valid Starting: {btn.valid_starting.toLocaleDateString()}
									</>
								)}
								{btn.valid_until && (
									<>
										<br />
										Valid Until: {btn.valid_until.toLocaleDateString()}
									</>
								)}
								{btn.next_payment && (
									<>
										<br />
										Next Payment Due: {btn.next_payment.toLocaleDateString()}
									</>
								)}
								{btn.dob && (
									<>
										<br />
										DOB: {btn.dob.toLocaleDateString()}
									</>
								)}
								{btn.signup_date && (
									<>
										<br />
										Member Since: {btn.signup_date.toLocaleDateString()}
									</>
								)}

								{btn.payments_remaining &&
									<>
										<br />
										Required Payments Remaining: {btn.payments_remaining}
									</>
								}

								{btn.payment_amount && (
									<>
										<br />
										Payment Amount: ${btn.payment_amount}
									</>
								)}

								{btn.totalPaid > 0 && btn.totalPaid && (
									<>
										<br />
										Total Paid: ${btn.totalPaid}
									</>
								)}

								{btn.last_visit && (
									<>
										<br />
										Last visit: {btn.last_visit.toLocaleString()}
									</>
								)}

								{btn.visits && (
									<>
										<br />
										Total Visits: {btn.visits}
									</>
								)}

								{btn.visits && btn.totalPaid && (
									<>
										<br />
										Paid Per Visit: {Math.round((btn.totalPaid / btn.visits) * 100) / 100}
									</>
								)}

								{btn.edit_url && (
									<>
										<br />
										<br />
										<span
											onClick={() =>
												openPopupWindow(
													btn.edit_url,
													"newWindow",
													"width=800,height=600"
												)
											}
										>
											View Subscription Portal
										</span>
									</>
								)}

							</>
						)}
						{btn.membership_type && btn.alert && (
								<>
									<br />
									<span className="member-alert">ALERT: {btn.alert}</span>
								</>
						)}
						{btn.price > 0 ? (
							<>
								<br />
								<span className="buttonprice" id={"buttonprice-" + j}>
									${btn.price.toFixed(2) || ""}
								</span>
							</>
						) : (
							""
						)}
						{btn.subtitle ? (
							<><br />
								<span className="buttonsubtotal" id={"buttonsubtitle-" + j}>
									{btn.subtitle}
								</span>
							</>
						) : (
							""
						)}
						{btn.date ? (
							<><br />
								<span className="buttonsubtotal" id={"buttondate-" + j}>
									Order Placed: {btn.date}
								</span>
							</>
						) : (
							""
						)}
						{btn.status ? (
							<><br />
								<span className={`buttonsubtotal`} id={"buttonstatus-" + j}>
									{btn.status}
								</span>
							</>
						) : (
							""
						)}
					</span>
				</div>
			);
		}
		if (currentTab != 8) setButtons(newbuttons); //create buttons[] from buttonlist
		//end grid creation
	}, [buttonlist, triggerButtonBuild, isDelete]); //build buttons from buttonlist

	//function addItem(e){

	async function addItem(itemOrEvent) {
		console.log('addItem', itemOrEvent);
		let sound = pop7;
		let newItem: Types.ExtendedLineItem;
		if (itemOrEvent && itemOrEvent.target) {
			// Handle MouseEvent as before
			const target = itemOrEvent.target;
			const id = target.id.split("-")[1];
			if (!id) return;
			newItem = { ...buttonlist[parseInt(id, 10)] }; // Get the item from buttonlist
			console.log('newItem', newItem)
		} else {
			// Handle object directly
			newItem = { ...itemOrEvent }; // Assume itemOrEvent is the object with item details
		}
		console.log('newItem', newItem)
		if (newItem.price < 0) {
			newItem.price = Math.abs(newItem.price);
			newItem.quantity = -newItem.quantity || -1;
		}
		let newOrder = [];

		if (orderId) {
			clearOrder(true, false); // Wait for clearOrder to finish
			//wait for clearOrder to finish
			await new Promise((resolve) => setTimeout(resolve, 200));
		} else {
			newOrder = [...thisOrderItems];
		}

		if (newItem.membership_type) {
			if (newItem.membership_type.includes('Premium')) {
				sound = bloop3;
			} else {
				sound = bloop1;
			}
		}

		if (newItem.membership_type && newItem.photo == nophoto) {
			if (newItem.photo == nophoto) {
				// If the member has no photo, set the selected member and show the upload form
				setSelectedMember(newItem);
				setShowPhotoUploadForm(true);
			} else {
				// Otherwise, proceed with your existing logic (e.g., showing member details)
				searchMembers(newItem.membership_number);
			}
		}

		if (
			newItem?.valid_until < new Date() ||
			newItem?.valid_starting > new Date()
		) {
			//console.log("not valid");
			sound = block1
			sound.play()
			return;
		}
		if (newItem.visitsToday == 1) {
			if (isBFF && newItem.title.includes('Premium')) {
				newItem.title = `BFF Checkin`
				newItem.attendance_category = 'BFF'
				newItem.calendar_category = null;
				sound = bloop2;
			} else {
				sound = block1;
				sound.play()

				//('already checked in')
				return
			}
		} else if (newItem.visitsToday > 1) {
			sound = block1;
			sound.play()

			return
		}

		let matchedIndex = -1;

		if (newItem.function == "searchOrders") {
			return setOrder(newItem.order);
			//searchOrders(newItem.title);
		} //if search function button
		if (newItem.sub_id > 0) {
			searchMembers(newItem.sub_id);
			//setSubSearch(newItem.sub_id)
		} //if member, find related members for suggestions
		if (!newItem.title && !newItem.name) {
			sound = block1;
			sound.play()

			return;
		} //exit if not a plu item button
		if (/^-?\d+$/.test(typedValue)) {
			console.log('quantity detected', typedValue, parseInt(typedValue, 10))
			newItem.quantity = parseInt(typedValue, 10);
			console.log('newItem', newItem)
			if (newItem.quantity < 10000) {
				setTypedValue("");
			} else {
				newItem.quantity = 1;
			}
			if (newItem.max_quantity && newItem.max_quantity < newItem.quantity) {
				newItem.quantity = newItem.max_quantity;
			}
		} //set quantity if number is typed
		console.log('newItem', newItem)
		//if the delete key is being held down, make the quantity negative
		if (isDelete) { newItem.quantity = -newItem.quantity }

		//test if typedValue is in the form 300*2299 or 5x1299 for custom rates on item
		if (newItem.function == "customrate" && /^[-\d]+([*x])(\d+(?:\.\d+)?)$/.test(typedValue)) {
			newItem.quantity = parseFloat(typedValue.split(/[*x]/)[0]);

			if (newItem.quantity < 100000) {
				setTypedValue("");
			} else {
				newItem.quantity = 1;
			}
			if (newItem.max_quantity && newItem.max_quantity < newItem.quantity) {
				newItem.quantity = newItem.max_quantity;
			}
			const ratestring = typedValue.split(/[*x]/)[1]
			//let rate = typedValue.split(/[*x]/)[1]
			//if it doesn't have a decimal, divide by 100
			let rate = 0
			if (!/\./.test(ratestring)) {
				if ((Number(ratestring) < 1)) {
					rate = Number(ratestring)
				} else {
					rate = Number(ratestring) / 100
				}
			} else {
				rate = Number(ratestring)
			}

			newItem.price = rate;
			newItem.fullPrice = rate;
		} else {
			newItem.fullPrice = newItem.price;
		}
		//console.log('newItem', newItem)
		if (currentTab !== 10 && newItem.modClass && newItem.modClass > 999 && Math.floor(newItem.modClass / 1000) != modClass) {
			//console.log('blocked!')
			sound = block1
			sound.play()
			return
		}//if item has mods and the mods it needs are not loaded
		setNumOfMods(
			(prevNumOfMods) =>
				Math.max(prevNumOfMods - 1, 0) + (newItem.numOfMods || 0)
		);

		if (!newItem.isMod || (Math.floor(newItem.modClass / 1000) === modClass)) {
			setModClass(newItem.modClass % 1000 || null);
			if (newItem.modClass % 1000 === 0) {
				setParentModClass(null);
			}
		}


		if (!newItem.isMod) {
			setParentModClass(newItem.modClass || 0);
		}
		setModUpdateTrigger(prev => !prev);
		setReqMods(newItem.required_mods || []);
		setOptMods(newItem.optional_mods || []);
		setAllMods(
			(newItem.required_mods || []).concat(newItem.optional_mods || [])
		);

		const modsToFetch = (newItem.required_mods || [])
			.concat(newItem.optional_mods || [])
			.join(",");
		if (!isFetchingMods && modsToFetch.length > 0) {
			setIsFetchingMods(true);
			try {
				const response = await fetch(
					`/api/get-mods?modClasses=${modsToFetch}`,
					{
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					}
				);
				setModItems(await response.json());
			} catch (error) {
				console.error("Failed to fetch mods:", error);
			} finally {
				setIsFetchingMods(false);
			}
		} else if (!newItem.isMod) {
			setModItems([]);
		}

		if (newItem.isMod) {
			addModifierToItem(newItem, newOrder);
			sound = pop1;
			sound.play();
		} else {
			const compareItems = (
				item1: Types.ExtendedLineItem,
				item2: Types.ExtendedLineItem
			) => {
				const { quantity: quantity1, photo: photo1, ...rest1 } = item1;
				const { quantity: quantity2, photo: photo2, ...rest2 } = item2;
				//console.log('comparing: ',item1,item2)
				return (
					quantity1 &&
					quantity2 &&
					JSON.stringify(rest1) === JSON.stringify(rest2)
				);
			};
			for (let i = 0; i < newOrder.length; i++) {
				if (compareItems(newItem, newOrder[i])) {
					if (
						newItem.max_quantity &&
						newOrder[i].quantity + newItem.quantity > newItem.max_quantity
					) {
						newItem.quantity = 0;
					}
					newOrder[i] = {
						...newOrder[i],
						quantity: newOrder[i].quantity + newItem.quantity,
					}; // Create a new object with updated quantity
					matchedIndex = i;
					break;
				}
			} //add to quantity if already exists
			if (matchedIndex !== -1) {
				const matchedItem = newOrder.splice(matchedIndex, 1)[0]; // remove the matched item
				newOrder.push(matchedItem); // add it back to the end
			} else {
				newOrder.push(newItem); // if no match, simply add the new item to the end
			}
			// Look for the item in the existing order
			const itemIndex = newOrder.findIndex((item) => item === newItem);
			// If the item exists, set it as the current modifying item
			if (itemIndex !== -1) {
				setModifyingItemIndex(itemIndex);
			}
			setThisOrderItems(newOrder);
			sound.play();
		}
	}

	function addModifierToItem(
		modifier: Types.ExtendedLineItem,
		newOrder: Types.LineItem[]
	) {
		//console.log('modifier',modifier)
		if (modifyingItemIndex !== null) {

			const currentItem = newOrder[modifyingItemIndex];
			if (modifier.id) {
				currentItem.id = modifier.id;
			}
			if (modifier.variant_id) {
				currentItem.variant_id = modifier.variant_id;
			}
			if (modifier.function == "overwrite") {
				console.log('overwriting', currentItem, modifier)
				currentItem.title = modifier.title;
				currentItem.price = modifier.price;
				currentItem.sku = modifier.sku;
				currentItem.properties = [];
				if (modifier.quantity < 0) {
					currentItem.quantity = -currentItem.quantity
					console.log('this should be negative', currentItem.quantity)
				}
				//currentItem.quantity = modifier.quantity;
			} else {
				if (!currentItem.properties) {
					currentItem.properties = [];
				}

				if (modifier.discountType) {
					let discAmt = 0;
					if (modifier.discountType == "%") {
						discAmt = parseFloat(
							((currentItem.price * modifier.price) / 100).toFixed(2)
						);

						currentItem.properties.push({
							name: "Mod",
							value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
						});
						currentItem.price -= discAmt;
					} else if (modifier.discountType == "$") {
						discAmt = parseFloat(modifier.price.toFixed(2));
						currentItem.properties.push({
							name: "Mod",
							value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
						});
						currentItem.price -= discAmt;
					} else if (modifier.discountType == "o") {
						discAmt = parseFloat((currentItem.price - modifier.price).toFixed(2));
						currentItem.properties.push({
							name: "Mod",
							value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
						});
						currentItem.price = modifier.price;
					}
				} else if (modifier.addPrice) {
					currentItem.properties.push({
						name: "Mod",
						value: `${modifier.title} (+$${modifier.price.toFixed(2)})`,
					});
					currentItem.price += modifier.price;
				} else
					currentItem.properties.push({
						name: "Mod",
						value: `${modifier.title}`,
					});

			}

			setThisOrderItems(newOrder);
		}
	}

	const handleBarcodeSubmit = async (event) => {
		event.preventDefault();
		//console.log('current item: ', contextMenu.currentItem);
		// Assuming `selectedPLUItem` holds the necessary item data, like an ID
		const pluItemId = contextMenu.currentItem?.sku || ''; // Ensure you have an 'id' or similar identifier

		const apiUrl = '/api/create-barcode'; // Your actual API endpoint
		const payload = {
			plu_id: pluItemId, // or any other identifier for the PLU item
			barcode: barcode
		};

		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${sessionStorage.getItem('token')}`, // if you need authentication
				},
				body: JSON.stringify(payload)
			});

			if (response.ok) {
				const result = await response.json();
				//console.log('Barcode added successfully:', result);
				// Perform any follow-up actions like updating the UI or state
				setShowBarcodeModal(false);
				setBarcode(''); // Reset the barcode input
			} else {
				// Handle errors
				const error = await response.json();
				console.error('Error adding barcode:', error);
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};

	const addBarcode = (item) => {
		//console.log("Adding barcode to", item);
		// Implement your logic to add a barcode here (e.g., show a form modal)
		setShowBarcodeModal(true);
		setContextMenu({ visible: false, x: 0, y: 0, currentItem: item }); // Close context menu
	};

	const handleEnterPress = (e) => {
		if (e.key === "Enter") {
		  e.preventDefault(); // Prevent the form from submitting
		  const formElements = e.target.form.elements; // Get all form elements
		  const currentIndex = Array.prototype.indexOf.call(formElements, e.target);
		  
		  // Move focus to the next input
		  const nextElement = formElements[currentIndex + 1];
		  if (nextElement && nextElement.tagName === "INPUT") {
			nextElement.focus();
		  }
		}
	  };
	  

	return (
		<div key="buttongrid" className="ButtonGrid" onClick={addItem}>
			<div className="tabs" style={{ gridArea: "1 / 1 / 6 / 61" }}>
				<>
					<button
						className={`tab-button ${currentTab === 1 ? "active" : ""}`}
						onClick={() => setCurrentTab(1)}
					>
						Admission
					</button>
					<button
						className={`tab-button ${currentTab === 2 ? "active" : ""}`}
						onClick={() => setCurrentTab(2)}
					>
						Merch
					</button>
					<button
						className={`tab-button ${currentTab === 3 ? "active" : ""}`}
						onClick={() => setCurrentTab(3)}
					>
						Food
					</button>
					<button
						className={`tab-button ${currentTab === 11 ? "active" : ""}`}
						onClick={() => setCurrentTab(11)}
					>
						Drinks
					</button>
				</>
				{renderPhotoUploadForm()}
				{modClass > 0 ? (
					<button
						className={`tab-button ${currentTab === 5 ? "active" : ""}`}
						onClick={() => {
							setCurrentTab(5);
						}}
					>
						Item Modifiers
					</button>
				) : (
					""
				)}
				{orderSearchResults.length >= 1 && (
					<button
						className={`tab-button ${currentTab === 6 ? "active" : ""}`}
						onClick={() => setCurrentTab(6)}
					>
						Order Search
					</button>
				)}
				{members.length > 0 && (
					<button
						className={`tab-button ${currentTab === 7 ? "active" : ""}`}
						onClick={() => setCurrentTab(7)}
					>
						Member Search
					</button>
				)}
				{modItems.length > 0 && (
					<button
						className={`tab-button ${currentTab === 8 ? "active" : ""}`}
						onClick={() => setCurrentTab(8)}
					>
						Inputs
					</button>
				)}
				{giftCards.length > 0 && (
					<button
						className={`tab-button ${currentTab === 9 ? "active" : ""}`}
						onClick={() => setCurrentTab(9)}
					>
						Gift Cards
					</button>
				)}
				<button
					className={`tab-button ${currentTab === 10 ? "active" : ""}`}
					onClick={() => setCurrentTab(10)}
				>
					Modifiers
				</button>
			</div>
			{showBarcodeModal && (
				<div className="modal-overlay">
					<div className="modal-content">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleBarcodeSubmit(e);
								//console.log('Submitting barcode:', barcode);
								setShowBarcodeModal(false);

							}}
						>
							<label htmlFor="barcode">Barcode:</label>
							<input
								id="barcode"
								type="text"
								value={barcode}
								onChange={(e) => setBarcode(e.target.value)}
							/>
							<button type="submit">Add Barcode</button>
							<button type="button" onClick={() => setShowBarcodeModal(false)}>Cancel</button>
						</form>
					</div>
				</div>
			)}
			{isAddBarcode && (
				<div className="modal-overlay">
					<div className="modal-content">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								//const { name, membership_type, dob, sub_id, barcode, membership_number } = req.body;
								//console.log('Submitting barcode:', barcode);
								const requestBody = JSON.stringify({
									membership_number: selectedMember?.membership_number,
									barcode: barcode,
								});

								// Fetch request to create a new member via the API
								fetch("/api/edit-member", {
									method: "POST",
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${sessionStorage.getItem("token")}`,
									},
									body: requestBody,
								})
									.then((response) => response.json())
									.then((data) => {
										console.log("Member edited:", data);

										// Handle success - e.g., update UI or state
									})
									.catch((error) => {
										console.error("Error editing member:", error);
										// Handle error - e.g., show error message to user
									});
								setIsAddBarcode(false);
								//search through current buttons for the member we just edited and update the barcode property
								const editedMemberIndex = buttonlist.findIndex(
									(member) => member.membership_number === selectedMember?.membership_number
								)
								console.log('editedMemberIndex', editedMemberIndex)
								buttonlist[editedMemberIndex].barcode = barcode
								setBarcode('');
								setTriggerButtonBuild(prev => prev + 1);
							}}
						>
							<label htmlFor="barcode">Barcode:</label>
							<input
								id="barcode"
								type="text"
								value={barcode}
								onChange={(e) => setBarcode(e.target.value)}
							/>
							<button type="submit">Add Barcode</button>
							<button type="button" onClick={() => setIsAddBarcode(false)}>Cancel</button>
						</form>
					</div>
				</div>
			)}
			{contextMenu.visible && (
				<div
					className="context-menu"
					style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
				>
					<ul>
						<li onClick={() => addBarcode(contextMenu.currentItem)}>Add Barcode</li>
					</ul>
				</div>
			)}
			{isEditMember && editedMember && (
				<>
					<div className="modal-overlay" onClick={() => setIsEditMember(false)}></div>
					<div className="modal-content">
						<div className="">
							<h1>Edit Membership Details</h1>
							<form onSubmit={handleSaveMember}>
								<label>
									Name:
									<input
										type="text"
										name="name"
										value={editedMember.name || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Membership Type:
									<input
										type="text"
										name="membership_type"
										value={editedMember.membership_type || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Date of Birth:
									<input
										type="date"
										name="dob"
										value={editedMember.dobstring || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Subscription ID:
									<input
										type="text"
										name="sub_id"
										value={editedMember.sub_id || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Barcode:
									<input
										type="text"
										name="barcode"
										value={editedMember.barcode || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Valid Until:
									<input
										type="date"
										name="valid_until_string"
										value={editedMember.valid_until_string || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<label>
									Alert:
									<input
										type="text"
										name="alert"
										value={editedMember.alert || ''}
										onChange={handleInputChange}
										onKeyDown={(e) => handleEnterPress(e)}
									/>
								</label>
								<br />
								<button type="submit">Save</button>
								<button type="button" onClick={() => setIsEditMember(false)}>Cancel</button>
							</form>
						</div>
					</div>
				</>
			)}

			{buttons ? buttons : ""}
		</div>
	);
};
export default ButtonGrid;
