import React, {useState} from 'react';
import './Party.scss';
import Checkout from '../Checkout/Checkout'
import { Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';



export default function Party(props){
    const [thisTimes,setThisTimes] = useState({});
    const [checkingSku,setCheckingSku] = useState(false);
    const [thisDates,setThisDates] = useState([]);
    const[detailsState,setDetailsState]=useState('none');
    const[partyPaymentState,setPartyPaymentState]=useState('none');
    const [partyTime,setPartyTime] = useState();
    const [partyTimeId,setPartyTimeId] = useState();
    const [partyDate,setPartyDate] = useState();
    const [partyContactFirstName,setPartyContactFirstName] = useState('');
    const [partyContactLastName,setPartyContactLastName] = useState('');
    const [partyContactPhone,setPartyContactPhone] = useState('');
    const [partyContactEmail,setPartyContactEmail] = useState('');
    const [partyType,setPartyType] = useState('Birthday Party');
    const [partyName,setPartyName] = useState('');
    const [partyAge,setPartyAge] = useState('');
    const [partyRate,setPartyRate] = useState('');
    const [defaultPartyRate,setDefaultPartyRate] = useState('');
    const [partyParticipants,setPartyParticipants] = useState('');
    const [partyFood,setPartyFood] = useState('');
    const [partyPizza,setPartyPizza] = useState('');
    const [partyDrinks,setPartyDrinks] = useState(['','','']);
    const [partyExtras,setPartyExtras] = useState('');
    const [partyOther,setPartyOther] = useState('');
    const [deposit,setDeposit] = useState({amount:6000})
    const [defaultDeposit,setDefaultDeposit] = useState(6000)
    const [readyForCheckout,setReadyForCheckout] = useState(false);
    //const [checkoutCreated,setCheckoutCreated] = useState(false);
    const [draftId,setDraftId] = useState();
    const [passedSku,setPassedSku] = useState('');
    const [completing,setCompleting] = useState(false);
    const [complete,setComplete] = useState(false);
    const [original,setOriginal] = useState('');
    const [infoRequested,setInfoRequested] = useState(false);
    const [infoReceived,setInfoReceived] = useState(false);
    const [originalPayment,setOriginalPayment] = useState('');
    const [originalSku,setOriginalSku] = useState('');
    const [originalVariantTitle,setOriginalVariantTitle] = useState('')
    const [originalTitle,setOriginalTitle] = useState('');
    const [originalTimeId,setOriginalTimeId] = useState('');
    const [originalId,setOriginalId] = useState('');
    const [originalPartyDate,setOriginalPartyDate] = useState('');
    const [originalPartyRate,setOriginalPartyRate] = useState('');
    const [originalOrderId,setOriginalOrderId] = useState('');
    const [originalLineItemId,setOriginalLineItemId] = useState('');
    const [passedSkuDone,setPassedSkuDone] = useState(false);
    //const [notify,setNotify] = useState(true);
    const navigate = useNavigate();


    if(!passedSku && window.location.href.includes('?sku=')){
        const passed = window.location.href.split('?sku=')[1].split('?')[0];
        setPassedSku(passed);
        //var newPassedUrl = window.location.href.split('?')[0];
        //window.history.pushState({}, null, newPassedUrl);
    }


    if(!original && window.location.href.includes('?original=')){
        const orig = window.location.href.split('?original=')[1].split('?')[0];
        setOriginal(orig);
        //var newOriginalUrl = window.location.href.split('?')[0];
        //window.history.pushState({}, null, newOriginalUrl);
    }


    const handleOriginal = () =>{
        if(!infoRequested){
            setInfoRequested(true);
            fetch(`/api/party-info/${original}`,{
                headers: { "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
            })
            .then(res => res.json())
            .then(info => {
                if(info.data && info.data.orders.edges.length >= 1){
                    const orders = info.data.orders.edges;
                    for(var k=0;k<orders.length;k++){
                        if(orders[k].node.id.split('/')[4] === original){
                            var order = orders[k].node;
                            setOriginalOrderId(order.id);
                            setOriginalPayment(order.totalReceivedSet.shopMoney.amount);
                            setDeposit({amount:0})
                            if(order.billingAddress.phone){
                                setPartyContactPhone(order.billingAddress.phone)
                            }
                            if(order.customer){
                                if(order.customer.firstName){
                                    setPartyContactFirstName(order.customer.firstName)
                                }
                                if(order.customer.lastName){
                                    setPartyContactLastName(order.customer.lastName)
                                }
                                if(order.customer.phone){
                                    setPartyContactPhone(order.customer.phone)
                                }
                                if(order.customer.email){
                                    setPartyContactEmail(order.customer.email)
                                }
                            }
                            for(var l=0;l<order.lineItems.edges.length;l++){
                                var item = order.lineItems.edges[l].node;
                                if(item.refundableQuantity>0 && item.title.includes('- Party Deposit')){
                                    handleSku(item.sku,true)
                                    setOriginalSku(item.sku);
                                    setOriginalTitle(item.title);
                                    setOriginalVariantTitle(item.variantTitle)
                                    setOriginalTimeId(item.variant.id)
                                    setOriginalPartyDate(item.product.title.split(' - ')[0])
                                    setOriginalId(item.product.id.split('/')[4])
                                    setOriginalLineItemId(item.id)
                                    fetch(`/api/party-times/${item.product.id.split('/')[4]}`,{
                                        headers: { "Content-type": "application/json; charset=UTF-8",
                                        "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
                                    })
                                    .then(res => res.json())
                                    .then(times => {
                                        setThisTimes({...times})
                                    });
                                    if(item.customAttributes){
                                        for(var m=0;m<item.customAttributes.length;m++){
                                            var attribute = item.customAttributes[m];
                                            if(attribute.value){
                                                //console.log('attribute: ',attribute);
                                                if(attribute.key.includes('Party Type')||attribute.key.includes('Birthday Type')){
                                                    //console.log("party type: ",attribute.value)
                                                    setPartyType(attribute.value);
                                                }
                                                else if((attribute.key.includes('Name'))&&(!attribute.key.includes('Contact'))&&(attribute.value)){
                                                    setPartyName(attribute.value);
                                                }
                                                else if(attribute.key.includes('Age')){
                                                    setPartyAge(attribute.value);
                                                }
                                                else if(attribute.key.includes('Rate')){
                                                    setPartyRate(attribute.value);
                                                    setOriginalPartyRate(attribute.value)
                                                }
                                                else if(attribute.key.includes('Participants')){
                                                    setPartyParticipants(attribute.value);
                                                }
                                                else if(attribute.key.includes('Phone')){
                                                    setPartyContactPhone(attribute.value);
                                                }
                                                else if(attribute.key.includes('Food')){
                                                    setPartyFood(attribute.value);
                                                }
                                                else if(attribute.key.includes('Pizza Type')){
                                                    setPartyPizza(attribute.value);
                                                }
                                                else if(attribute.key.includes('Drink')){
                                                    var oldPartyDrinks = partyDrinks;
                                                    var values = attribute.value.split(',');
                                                    for(var n=0;n<values.length;n++){
                                                        if(!oldPartyDrinks[0]){
                                                            oldPartyDrinks[0] = values[n];
                                                        }else if(!oldPartyDrinks[1]){
                                                            oldPartyDrinks[1] = values[n];
                                                        }else if(!oldPartyDrinks[2]){
                                                            oldPartyDrinks[2] = values[n];
                                                        } else{
                                                            var oldDrinkExtras = partyExtras;
                                                            var newDrinkExtras = 'Additional Drink Type: '+values[n]+'\n';
                                                            setPartyExtras(oldDrinkExtras+newDrinkExtras);
                                                        }
                                                    }
                                                    setPartyDrinks(oldPartyDrinks);
                                                }
                                                else if((attribute.key.includes('Whole')||attribute.key.includes('Pitcher'))){
                                                    var oldWholeExtras = partyExtras;
                                                    var newWholeExtras = attribute.key+' : '+attribute.value+'\n';
                                                    setPartyExtras(oldWholeExtras+newWholeExtras);
                                                }
                                                else if(attribute.value){
                                                    var oldOther = partyOther;
                                                    var newOther = attribute.key+' : '+attribute.value+'\n';
                                                    setPartyOther(oldOther+newOther);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }  
                } else{
                    setInfoRequested(false);
                }
            })
            .then(done =>{
                //console.log(done);
                setInfoReceived(true);
            })
        }
    }


    const completeOrder = () =>{
        if(!completing && !complete){
            setCompleting(true);
            fetch(`/api/complete-order`,{
                method: 'POST',
                body: JSON.stringify({
                    id: draftId
                }),
                headers: {'Content-type': 'application/json; charset=UTF-8',
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
            .then(res => res.json())
            .then(response => {
                if(response.data.draftOrderComplete.draftOrder){
                    if(original){
                    fetch(`/api/cancel`,{
                        method: 'POST',
                        body: JSON.stringify({
                            orderId: originalOrderId,
                            lineItemId: originalLineItemId
                        }),
                        headers: {'Content-type': 'application/json; charset=UTF-8',
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
                    })
                    .then(res => res.json())
                    .then(response => {
                        setComplete(true);
                        toast.success("The party has been edited!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        navigate('/parties');
                    })
                    .catch(error => console.log(error.message));
                    } else{
                        setComplete(true);
                        toast.success("Reservation Confirmed!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        navigate('/parties');
                    }
                } else {
                    alert(response.data.draftOrderComplete.userErrors[0].message)
                }
                setCompleting(false);
            }) 
        }
    }


    const createDraftOrder = () => {
        var draftOrderBody = ''
        if(deposit.amount === defaultDeposit){
            draftOrderBody = JSON.stringify({
                email: partyContactEmail,
                billingAddress:{
                    firstName:partyContactFirstName,
                    lastName:partyContactLastName,
                    phone:partyContactPhone
                },
                lineItems: [{
                    variantId:partyTimeId,
                    quantity: 1,
                    customAttributes: [{
                        key:"Party Type",
                        value:partyType
                    },{
                        key:"Name",
                        value:partyName
                    },{
                        key:"Age",
                        value:partyAge
                    },{
                        key:"Rate",
                        value:partyRate
                    },{
                        key:"Estimated Number of Participants",
                        value:partyParticipants
                    },{
                        key:"Food Option",
                        value:partyFood
                    },{
                        key:"Pizza Type",
                        value:partyPizza
                    },{
                        key:"Drink Choice",
                        value:`${partyDrinks[0]}, ${partyDrinks[1]}, ${partyDrinks[2]}`
                    },{
                        key:"Whole Pizzas and Pitchers",
                        value:partyExtras
                    },{
                        key:"Other Details",
                        value: partyOther
                    },{
                        key:"Contact Info",
                        value:`${partyContactFirstName} ${partyContactLastName} - ${partyContactPhone} - ${partyContactEmail}`
                    }]
                  }]
            })
        }
        if(deposit.amount > defaultDeposit){
            draftOrderBody = JSON.stringify({
                email: partyContactEmail,
                billingAddress:{
                    firstName:partyContactFirstName,
                    lastName:partyContactLastName,
                    phone:partyContactPhone,
                    countryCode:"US"
                },
                lineItems: [{
                    variantId:partyTimeId,
                    quantity: 1,
                    customAttributes: [{
                        key:"Birthday Type",
                        value:partyType
                    },{
                        key:"Name",
                        value:partyName
                    },{
                        key:"Age",
                        value:partyAge
                    },{
                        key:"Rate",
                        value:partyRate
                    },{
                        key:"Estimated Number of Participants",
                        value:partyParticipants
                    },{
                        key:"Food Option",
                        value:partyFood
                    },{
                        key:"Pizza Type",
                        value:partyPizza
                    },{
                        key:"Drink Choice",
                        value:`${partyDrinks[0]} ${partyDrinks[1]} ${partyDrinks[2]}`
                    },{
                        key:"Whole Pizzas and Pitchers",
                        value:partyExtras
                    },{
                        key:"Other Details",
                        value: partyOther
                    },{
                        key:"Contact Info",
                        value:`${partyContactFirstName} ${partyContactLastName} - ${partyContactPhone} - ${partyContactEmail}`
                    }]
                  },{
                      title:"Additional Party Deposit",
                      quantity:1,
                      originalUnitPrice:(deposit.amount-defaultDeposit)/100,
                      taxable:false,
                      requiresShipping:false
                  }]
            })
        }
        if(deposit.amount < defaultDeposit){
            draftOrderBody = JSON.stringify({
                email: partyContactEmail,
                billingAddress:{
                    firstName:partyContactFirstName,
                    lastName:partyContactLastName,
                    phone:partyContactPhone
                },
                lineItems: [{
                    variantId:partyTimeId,
                    quantity: 1,
                    appliedDiscount:{
                        value:(defaultDeposit-deposit.amount)/100,
                        valueType:'FIXED_AMOUNT'
                    },
                    customAttributes: [{
                        key:"Birthday Type",
                        value:partyType
                    },{
                        key:"Name",
                        value:partyName
                    },{
                        key:"Age",
                        value:partyAge
                    },{
                        key:"Rate",
                        value:partyRate
                    },{
                        key:"Estimated Number of Participants",
                        value:partyParticipants
                    },{
                        key:"Food Option",
                        value:partyFood
                    },{
                        key:"Pizza Type",
                        value:partyPizza
                    },{
                        key:"Drink Choice",
                        value:`${partyDrinks[0]}, ${partyDrinks[1]}, ${partyDrinks[2]}`
                    },{
                        key:"Whole Pizzas and Pitchers",
                        value:partyExtras
                    },{
                        key:"Other Details",
                        value: partyOther
                    },{
                        key:"Contact Info",
                        value:`${partyContactFirstName} ${partyContactLastName} - ${partyContactPhone} - ${partyContactEmail}`
                    }]
                  }]
            })
        }




        fetch(`/api/create-draft-order`,{
            method: 'POST',
            body: draftOrderBody,
            headers: {'Content-type': 'application/json; charset=UTF-8',
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => res.json())
        .then(response => {
            if(response.data.draftOrderCreate.draftOrder){
                setDraftId(response.data.draftOrderCreate.draftOrder.id)
                setReadyForCheckout(true);
                togglePartyPaymentState();
            } else {
                alert(JSON.stringify(response.data.draftOrderCreate.userErrors[0].message))
            }
        }) 
    }

    const getDates = () =>{
        fetch(`/api/party-dates`,{
            headers: { "Content-type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
        })
        .then(res => res.json())
        .then(dates => setThisDates({...dates}))
    }

    const getTimes = (date) => {
        console.log('getting times for date',date)
        setThisTimes({});
        setPartyTime();
        setPartyTimeId();
        setPartyDate(date.target.value);
        fetch(`/api/party-times/${date.target.value}`,{
            headers: { "Content-type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
        })
        .then(res => res.json())
        .then(times => {
            setThisTimes({...times})
        })
    };

    const handleSku = (sku) =>{
        if(!checkingSku&&(!passedSku||!passedSkuDone)){
            console.log('handling the sku!')
            setCheckingSku(true);
            console.log('party ID exists... continuing',sku)
            fetch(`/api/party-times/${sku}`,{
                headers: { "Content-type": "application/json; charset=UTF-8",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
            })
            .then(res => res.json())
            .then(theTimes => {
                console.log('sku info obtained!')
                const dates = theTimes.data.products.edges
                for (var m=0; m<dates.length; m++){
                    const times = dates[m].node.variants.edges
                    for(var n=0; n<times.length; n++){
                        if (times[n].node.sku === sku){
                            console.log(times[n].node)
                            setPartyTimeId(times[n].node.id);
                            setPartyTime(times[n].node.title);
                            setPartyDate(dates[m].node.title.split(" - ")[0]);
                            if(!originalPayment){
                                setDeposit({amount:times[n].node.price*100})
                            }else{
                                const difAmount = times[n].node.price*100-Number(originalPayment)*100
                                if(difAmount<0){
                                    setDeposit({amount:0})
                                }
                                setDeposit({amount:Math.max(0,difAmount)})
                            }
                            setDefaultDeposit(times[n].node.price*100)
                            //console.log(times[n].node.price*100)
                            var skuRate = '$32/Person';
                            if(times[n].node.sku.includes('TP')){
                                ////console.log('TP party!')
                                if(times[n].node.sku<'03000000'||times[n].node.sku>'10000000'){
                                    skuRate = '$26/Person'
                                } else{
                                    skuRate = '$30/Person'
                                }
                            }else{
                                //console.log('not TP party!',partyTime)
                                if(times[n].node.sku<'03000000'||times[n].node.sku>'10000000'){
                                    skuRate = '$28/Person'
                                }
                            }

                            setPartyRate(skuRate)
                            setDefaultPartyRate(skuRate)
                            setCheckingSku(false);
                            setPassedSkuDone(true)
                        }
                    }
                }
                console.log('done handling the sku')
                //setCheckingSku(false);
            })
        }
    }

    const createTimeslots = () =>{
        if(passedSku){
            return(<div>
                    {partyTime}
                </div>)
        }
        const timeArray = [];
        if(!partyDate){
            return <i>Select a date to view available times.</i>;
        } 
        if(!thisTimes.data || !thisTimes.data.products.edges[0]){
            return <i>Loading times for {partyDate.split(", ")[0].split("day ")[1]}...</i>;
        } 
        const times = thisTimes.data.products.edges[0].node.variants.edges;
        console.log('times',times)

        if(partyDate===originalPartyDate||partyDate===originalId){
            timeArray.push(          
                <option id={partyDate.replace(/\s+/g, '')+'-label'} value={originalTimeId+'~'+originalVariantTitle+'~'+originalSku+'~'+originalTitle.split(" - ")[0]} selected="selected">Original: {originalVariantTitle}</option>
            )
        } else{
            timeArray.push(          
                <option id={partyDate.replace(/\s+/g, '')+'-label'} value='' selected="selected">Select a time:</option>
            )
        }


        for(var i=0;i<times.length;i++){
            timeArray.push(
                <option value={times[i].node.id+'~'+times[i].node.title+'~'+times[i].node.sku+'~'+thisTimes.data.products.edges[0].node.title.split(" - ")[0]} disabled={times[i].node.inventoryQuantity<=0}>{times[i].node.title}</option>
            )
        }
        return (
            <select id="time-select" onChange={function(e){
                //console.log(JSON.stringify(e.target.value))
                handleSku(e.target.value.split("~")[2])
            }}>{timeArray}</select>
        )
    }



    

    const createDateOptions = () => {
        if (passedSku) {
            handleSku(passedSku);
            return partyDate;
        }
        const dateArray = [];
        if (!thisDates.data) {
            return <h4>Loading...</h4>;
        }
        const dates = thisDates.data.collections.edges[0].node.products.edges;
        if (originalSku) {
            dateArray.push(
            <option value={originalId}>Original: {originalTitle.split(" - ")[0]}</option>
            );
        } else {
            dateArray.push(
            <option>{passedSku ? partyDate : "Choose a Date..."}</option>
            );
        }
        
        // Get the current date and set the hours, minutes, and seconds to 0
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        
        for (var i = 0; i < dates.length; i++) {
            const optionDate = new Date(dates[i].node.title.split(" - ")[0]);
        
            // Filter out dates in the past
            if (optionDate >= currentDate) {
            dateArray.push(
                <option
                value={dates[i].node.id.split("/")[4]}
                disabled={dates[i].node.totalInventory <= 0}
                >
                {dates[i].node.title.split(" - ")[0]}
                </option>
            );
            }
        }
        return (
            <select onChange={getTimes}>
            {dateArray}
            </select>
        );
    };
              

    const toggleDetailsState = () =>{
        if(detailsState === 'none'){
            setDetailsState('');
        } else{
            setDetailsState('none');
        }
    }

    const togglePartyPaymentState = () =>{
        if(partyPaymentState === 'none'){
            setPartyPaymentState('');
        } else{
            setPartyPaymentState('none');
        }
    }

    const partyDateChecker = () =>{
        return partyTimeId?true:false;
    }

    const partyContactChecker = () =>{
        var isContact = false;
        if(partyContactEmail&&partyContactFirstName&&partyContactLastName&&partyContactPhone){
            isContact = true;
        }
        return isContact?true:false;
    }

    const continueToDetailsButton = () =>{
        if(partyDateChecker()){
            if(partyContactChecker()){
                return (
                    <button className="fullWidth" onClick={toggleDetailsState}>Continue to Party Details</button>
                ) 
            } else{
                return(
                    <div>
                        <i>Complete Contact Info to Continue.</i><br/><br/>
                        - or -<br/><br/>
                        <button className="" onClick={toggleDetailsState}>Continue to Party Details Without Contact Info</button>
                    </div>
                )            
            }
        } else {
            if(partyContactChecker()){
            return (
                <div>
                <i>Select a Party Time to Continue.</i><br/>
                </div>
            )
            } else{
                return (
                    <div>
                    <i>Select a Party Time and Complete All Contact Info to Continue.</i><br/>
                    </div>
                )
            }
        }

    }

    const handleDrinks = (e) =>{
        let drinks = partyDrinks;
        drinks[e.target.name-1] = e.target.value;
        //console.log('drinks: ',drinks)
        setPartyDrinks(drinks);
    }

    const pizzaType = () =>{
        if(partyFood.includes('Pizza')){
            return(
                <div>
                    <b>Pizza Type</b><br/><select value={partyPizza} onChange={function(e){setPartyPizza(e.target.value)}}>
                        <option value={partyPizza||''}>{partyPizza||'Select a Pizza Option'}</option>
                        <option value="Pepperoni">Pepperoni</option>
                        <option value="Cheese">Cheese</option>
                        <option value="1/2 Pep 1/2 Ch" >1/2 Pep 1/2 Ch</option>
                        <option value="Other (see notes)">Other (See notes)</option>
                        
                    </select><br/><br/>
                </div>
            )
        }
    }

    const drinkType = () =>{
        if(partyFood.includes('Drink')||(!partyTime?.includes('Midway')&&!partyTime?.includes('Turning'))){
            return(
                <div>
                        <b>Drinks</b><br/><select id="drinks-1" name="1" onChange={handleDrinks}>
                            <option value={partyDrinks[0]||""}>{partyDrinks[0]||"Drink Choice #1"}</option> 
                            <option value="Coke">Coke</option> 
                            <option value="Coke Zero">Coke Zero</option> 
                            <option value="Sprite">Sprite</option> 
                            <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                            <option value="Dr Pepper">Dr Pepper</option> 
                            <option value="Barq's Root Beer">Barq's Root Beer</option> 
                            <option value="Sweet Tea">Sweet Tea</option> 
                            <option value="Unsweet Tea">Unsweet Tea</option> 
                            <option value="Water Bottles">Water Bottles</option> 
                               
                        </select><select id="drinks-2" name="2" onChange={handleDrinks}>
                            <option value={partyDrinks[1]||""}>{partyDrinks[1]||"Drink Choice #2"}</option>
                            <option value="Coke">Coke</option> 
                            <option value="Coke Zero">Coke Zero</option> 
                            <option value="Sprite">Sprite</option> 
                            <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                            <option value="Dr Pepper">Dr Pepper</option> 
                            <option value="Barqs Root Beer">Root Beer</option> 
                            <option value="Sweet Tea">Sweet Tea</option> 
                            <option value="Unsweet Tea">Unsweet Tea</option> 
                            <option value="Water Bottles">Water Bottles</option>    
                        </select><select id="drinks-3" name="3" onChange={handleDrinks}>
                            <option value={partyDrinks[2]||""}>{partyDrinks[2]||"Drink Choice #3"}</option>
                            <option value="Coke">Coke</option> 
                            <option value="Coke Zero">Coke Zero</option> 
                            <option value="Sprite">Sprite</option> 
                            <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                            <option value="Dr Pepper">Dr Pepper</option> 
                            <option value="Barqs Root Beer">Root Beer</option> 
                            <option value="Sweet Tea">Sweet Tea</option> 
                            <option value="Unsweet Tea">Unsweet Tea</option> 
                            <option value="Water Bottles">Water Bottles</option>    
                        </select><br/><br/>
                </div>
            )
        }
    }


    const createCheckout = (amount) =>{
        if(readyForCheckout && amount >= 1 && partyPaymentState !== "none"){
            return(<Checkout originalLineItemId={originalLineItemId} originalOrderId={originalOrderId} logout={logout} depositAmount={amount} email={partyContactEmail} name={partyContactFirstName+' '+partyContactLastName} phone={partyContactPhone} id={draftId}/>)
        } else if(amount === 0){
        return(<button className="resbutton" onClick={completeOrder}>{original?'Save All Party Edits':'Reserve Now'}</button>)
        } else {
            return(<p>Not ready for checkout...</p>)
        }
    }

    const logout = () =>{
        //console.log('logging out...')
        props.setUser({});
    }

    const partyBookButton = () => {
        if(originalSku||passedSku){
            return(
                <Link to="/parties"><button>Go Back To Party Book</button></Link>
            )
        }
    }

    const reminders = () =>{
        if(!original){
            return(
                <div className="remindersDiv">
                    <input type="checkbox" id="list-check"></input><label htmlFor="list-check"><b>Party List Reminder</b><br/><i className="tinyText">On the day of your party, please bring in a list of names for those who you would like to receive a wristband. This list should include all children ages 3-15 who have been invited. Adults (16+) are free to enter the park, and only need to be included if you would like to purchase wristbands for them to play games and ride rides.
                    </i></label><br/><br/>
                    <input type="checkbox" id="socks-check"></input><label htmlFor="socks-check"><b>Socks/Bathing Suit Reminder</b><br/><i className="tinyText">Some attractions at ZDT's require socks to participate. Please remind all of your guests to make sure to bring socks if they wish to participate in these attractions! The water rides may be open, weather permitting (zdtamusement.com/hours). If the water rides may be open, we recommend reminding your guests to bring a bathing suit as well. 
                    </i></label><br/><br/>
                    <input type="checkbox" id="email-check"></input><label htmlFor="email-check"><b>Deposit</b><br/><i className="tinyText">There is a ${defaultDeposit/100} deposit due today to reserve the room, which is non-refundable but will be applied toward your party. Did you have any other questions before we proceed to your credit card info?
                    </i></label><br/><br/><br/><br/>
                </div>
            )
        }
        /* 
        else {
            return(
                <div className="notifyCheck">
                    <b>Send a New Party Email Notification?</b><br/>
                    <select type="select" id="notify" value={notify} onChange={function(e){setNotify(e.target.value)}}>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select><br/><br/>
                </div>
            )
        }
        */
    }

    const depositChecker = () =>{
        if (originalPayment){
            //var oldDeposit = deposit;
            //var newDeposit = deposit.amount - originalPayment*100;
            return(
                <div className="depositDiv">
                    <b>Amount Paid Previously: $ {Number(originalPayment).toFixed(2)}</b><br/>
                    Additional Deposit Amount Needed? ($)
                    <input 
                        type="num" 
                        value={deposit.amount/100} 
                        onChange={function(e){setDeposit({amount:e.target.value*100})}}>
                    </input><br/><br/>
                    <button onClick={createDraftOrder}>{deposit.amount>0?"Continue to Payment":"Continue"}</button>
                </div>
            )
        } else{
            return(
                <div className="depositDiv">
                    <b>Deposit Amount ($)</b><br/> 
                    <input 
                        type="num" 
                        value={deposit.amount/100} 
                        onChange={function(e){setDeposit({amount:e.target.value*100})}}
                    ></input>
                    <br/><br/>
                    <button onClick={createDraftOrder}>{deposit.amount>0?"Continue to Payment":"Continue With No Deposit"}</button>
                </div>
            )


        }
    }



    if((!original&&thisDates.data)||(original&&infoReceived)){
        return( 
            <div className="partywindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="partyselection" style={{display:detailsState?'':'none'}}>
                    <h3>Party Date and Time:</h3>
                    {createDateOptions()}
                    <br/><br/>
                    
                    {createTimeslots()}
                    <br/><br/>
                    {partyBookButton()}
                    <br/>
                    <h3>Customer Contact Info:</h3>
                    <b>First Name</b><br/><input type="text" value={partyContactFirstName} onChange={function(e){setPartyContactFirstName(e.target.value)}}></input><br/><br/>
                    <b>Last Name</b><br/> <input type="text" value={partyContactLastName} onChange={function(e){setPartyContactLastName(e.target.value)}}></input><br/><br/>
                    <b>Phone Number</b><br/><input type="tel" value={partyContactPhone} onChange={function(e){setPartyContactPhone(e.target.value)}}></input><br/><br/>
                    <b>Email Address</b><br/><input type="email" value={partyContactEmail} onChange={function(e){setPartyContactEmail(e.target.value)}}></input><br/><br/><br/>
                    
                    {continueToDetailsButton()}
                </div>
                <div className="partyDetails" style={{display:detailsState}}>
                        <h3>{partyDate?partyDate.split(' - ')[0]:''}</h3><h6>{partyTime}</h6>
                        <h5>{partyContactFirstName} {partyContactLastName}</h5> <h6>{partyContactPhone}</h6><h6>{partyContactEmail}</h6><br/>
                        <button className="" onClick={toggleDetailsState}>Go Back</button><br/><br/><hr/><br/>

                        <b>Type of Party</b><br/><select value={partyType} onChange={function(e){setPartyType(e.target.value)}}>
                            <option value="Birthday Party">Birthday Party</option>
                            <option value="Team Party">Team Party</option>
                            <option value="Company Party">Company Party</option>
                            <option value="Other">Other</option>
                            
                        </select><br/><br/>
                        <b>Name</b><br/><input type="text" value={partyName} onChange={function(e){setPartyName(e.target.value)}}></input><br/><br/>
                        <b>Age</b><br/><input type="text" value={partyAge} onChange={function(e){setPartyAge(e.target.value)}}></input><br/><br/>
                        <b>Base Party Rate</b><br/><select value={partyRate} onChange={function(e){setPartyRate(e.target.value)}}>
                            <option value={originalPartyRate||defaultPartyRate}>{originalPartyRate||defaultPartyRate}</option>
                            <option value="Other (see notes)">Other (see notes)</option>
                            <option value={defaultPartyRate}>{defaultPartyRate}</option>
                        </select>
                        <br/>
                        {(originalPartyRate&&originalPartyRate!==partyRate)?"Notice: the party rate was changed from the original rate. To keep the original party rate, please select that option above.":""}
                        <br/><br/>
                        <b>Estimated Number of Participants?</b><br/><input value={partyParticipants} type="text" onChange={function(e){setPartyParticipants(e.target.value)}}></input><br/><br/>
                        <b>Food Option</b><br/><select className="smallselect" value={partyFood} onChange={function(e){setPartyFood(e.target.value)}}>
                            <option value={partyFood||''}>{partyFood||''}</option>
                            <option value="None.">None</option>
                            {partyTime && !partyTime.includes('Midway') && !partyTime.includes('Turning')&&
                                <option value="2 Slices of Pizza Each (+$7/Person)">2 Slices of Pizza(+$7/Person)</option>
                            }
                            {partyTime && (partyTime.includes('Midway')||partyTime.includes('Turning'))&&
                                <option value="2 Slices of Pizza and a Medium Drink Each (+$7/Person)">2 Slices of Pizza and a Medium Drink Each(+$7/Person)</option>
                            }
                        </select><br/><br/>
                        {pizzaType()}
                        {drinkType()}
                        <b>Any Whole Pizzas or Pitchers?</b><br/><i className="tinyText">Whole Pep 16": $22.99<br/>Whole Cheese 16": $18.99<br/>Pitchers: $7.99<br/>Pitcher Refill: $4.99</i><br/> <textarea rows="4" value={partyExtras} onChange={function(e){setPartyExtras(e.target.value)}}></textarea><br/><br/>
                        <b>Other Notes</b><br/> <textarea value={partyOther} rows="8" onChange={function(e){setPartyOther(e.target.value)}}></textarea><br/><br/>
                        {reminders()}
                        {depositChecker()}
                        <br/><br/>
                    </div>
                    <div className="partyPayment" style={{display:partyPaymentState}}>
                        <h3>{partyDate?partyDate.split(' - ')[0]:''}</h3><h6>{partyTime}</h6>
                        <h5>{partyContactFirstName} {partyContactLastName}</h5> <h6>{partyContactPhone}</h6><h6>{partyContactEmail}</h6><br/>
                        <h6>Birthday Type:{partyType}</h6>
                        <h6>Name: {partyName}</h6>
                        <h6>Age: {partyAge}</h6>
                        <h6>Base Rate: {partyRate}</h6>
                        <h6>Est # of Participants: {partyParticipants}</h6>
                        <h6>Food Option: {partyFood}</h6>
                        <h6>Pizza Type: {partyPizza}</h6>
                        <h6>Drink Choice: {`${partyDrinks[0]}, ${partyDrinks[1]}, ${partyDrinks[2]}`}</h6>
                        <h6>Whole Pizzas and Pitchers: {partyExtras}</h6>
                        <h6>Other Details: {partyOther}</h6><br/><br/>
                        <button className="backbutton" onClick={togglePartyPaymentState}>Go Back</button><br/><br/>
                        <hr/><br/>
                        <h2><b>{deposit.amount?('$'+deposit.amount/100+' Deposit'):''}</b></h2>
                        {createCheckout(deposit.amount)}
                    </div>
            </div>
        )
    } else{
        if(original){
            handleOriginal(original)
        } else{
            getDates();
        }
        return(
            <div className="partywindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="partyselection">
                    <h1>Loading...</h1>
                </div>
            </div>
        )
    }

}