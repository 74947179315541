import React, {useState, useEffect} from 'react';
import './Parties.scss';
import Timeslot from '../TimeSlot/Timeslot'



export default function Party(){

    const [thisTimes,setThisTimes] = useState({});
    const [thisDates,setThisDates] = useState([]);
    const [partyDate,setPartyDate] = useState();
    const [error, setError] = useState(null);
    const [, setResizeCount] = useState(0);

    useEffect(() => {
        // Function to be called on resize
        function handleResize() {
            setResizeCount(prev => prev + 1);
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup: remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const getDates = () => {
        if (error) {
          return;
        }
      
        fetch(`/api/party-dates`,{
            headers: { "Content-type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
        })
          .then(res => {
            if (res.status === 304) {
              throw new Error('No new data available');
            }
            if (!res.ok) {
              throw new Error('Network request failed');
            }
            return res.json();
          })
          .then(dates => setThisDates({ ...dates }))
          .catch(error => {
            console.log(error);
            setError(error);
          });
      };
      

    const getTimes = (date) => {
        setThisTimes({});
        //console.log((typeof date === 'object')?date.target.value:date)
        if(typeof date === 'object'){
            setPartyDate(date.target.value);
            fetch(`/api/party-times/${(typeof date === 'object')?date.target.value:date}`,{
                headers: { "Content-type": "application/json; charset=UTF-8",
               "Authorization": `Bearer ${sessionStorage.getItem('token')}` }   
            })
            .then(res => res.json())
            .then(times => {
                //if(times.data.products.edges.length>1){console.log('danger! multiple possible results found.');}
                //console.log(JSON.stringify(times.data.products.edges))
                setThisTimes({...times})
            })
        } else{
            setPartyDate(date);
            fetch(`/api/party-times/${date}`,{
                headers: { "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}` }
            })
            .then(res => res.json())
            .then(times => {
                setThisTimes({...times})
            })
        }

    };




    
    const createTimeslots = () =>{
        const partyArray = [];
        partyArray.push(
            <div id={`partyRoomTitleLarge`} style={{gridArea: `1/1/span 1/span 1`}}>
                <h2 className="partyRoomTitle">Large Party Room</h2>
            </div>
        )
        partyArray.push(
            <div id={`partyRoomTitleCarousel`} style={{gridArea: `${window.screen.width<601?'100/1':'1/2'}/span 1/span 1`}}>
                <h2 className="partyRoomTitle">Carousel Party Room</h2>
            </div>
        )
        partyArray.push(
            <div id={`partyRoomTitleMidway`} style={{gridArea: `200/1/span 1/span 2`}}>
                <h2 className="partyRoomTitle">Midway Party Room</h2>
            </div>
        )        
        partyArray.push(
            <div id={`partyRoomTitleTurning`} style={{gridArea: `300/1/span 1/span 2`}}>
                <h2 className="partyRoomTitle">Turning Point Party Room</h2>
            </div>
        )
        if(!partyDate){
            return (<i>Select a date to view the party book.</i>)
        } 
        if(!thisTimes.data || !thisTimes.data.products.edges[0]){
            return (<i>Loading parties for {partyDate.split(", ")[0].split("day ")[1]}...</i>)
        } 
        const parties = thisTimes.data.products.edges[0].node.variants.edges;
        for(var i=0;i<parties.length;i++){
            partyArray.push(<Timeslot id={parties[i].node.id} party={parties[i]}/>)
        }
        return (
            <div id='partyList' className="partiesListed">
                {partyArray}
            </div>
        )
    }

    const createDateOptions = ()=>{
        const dateArray = [];
        if(!thisDates.data){
            return <h4>Loading...</h4>;
        }
        const dates = thisDates.data.collections.edges[0].node.products.edges;
        const today = new Date();
        today.setTime(today.getTime() - (24 * 60 * 60 * 1000)); // Subtract one day from 'today' to get 'yesterday'
        for(var i=0;i<dates.length;i++){
            if (dates[i].node.variants && dates[i].node.variants.edges.length > 0) {
                const sku = dates[i].node.variants.edges[0].node.sku;
                const skuYear = 2000 + parseInt(sku.slice(4, 6));
                const skuMonth = parseInt(sku.slice(0, 2)) - 1;
                const skuDay = parseInt(sku.slice(2, 4));
                const skuHour = parseInt(sku.slice(6, 8));
                const dateFromSKU = new Date(skuYear, skuMonth, skuDay, skuHour);
                if (dateFromSKU >= today) {
                    dateArray.push(
                        <option value={dates[i].node.id.split('/')[4]} 
                        selected={i<1?true:false}>
                            {dates[i].node.title.split(',')[0]} ({dates[i].node.totalInventory}/{dates[i].node.totalVariants})
                        </option>
                    )
                }
            }
        }
        if(!partyDate){
            getTimes(dates[0].node.id.split('/')[4])
        }
        return(
            <select onChange={getTimes}>
                {dateArray}
            </select>
        )
    }
    
    
    

    if (error) {
        return (
            <div className="partieswindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="partiesselection">
                    <h3>Error: {error}</h3>
                </div>
            </div>
        )
    } else if(thisDates.data){
        return( 
            <div className="partieswindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="partiesselection">
                    {createDateOptions()}
                    <br/><br/>
                    {createTimeslots()}
                </div>
            </div>
        )
    } else{
        getDates();
        return(
            <div className="partieswindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="partiesselection">
                    <h3>Loading Available Party Dates...</h3>
                </div>
            </div>
        )
    }

}