import React, { useState, useEffect, useRef } from "react";
import "./PaymentButtons.scss";
import * as Types from "../POSWindow/POSTypes";

interface PaymentButtonsProps {
	createOrder: () => void;
	transactions: Types.Transaction[];
	setTransactions: (newTransactions: Types.Transaction[]) => void;
	totalPrice: number;
	subTotalPrice: number;
	taxLines: Types.TaxLine[];
	orderId: number;
	fulfillAllOrders: (notify: boolean, printOrder: boolean) => void;
	fulfilled: string | null;
	discountCodes: Types.DiscountCode[];
	setDiscountCodes: (discountCodes: Types.DiscountCode[]) => void;
	setSubtotalPrice: (number: number) => void;
	setTaxLines: (taxLines: Types.TaxLine[]) => void;
	setTotalPrice: (number: number) => void;
	changeAmount: number;
	setChangeAmount: (number: number) => void;
	typedValue: string;
	setTypedValue: (string: string) => void;
	reprintable: boolean;
	clearOrder: () => void;
	orderNotes: string;
	setOrderNotes: (string: string) => void;
	addNotesToOrder: () => void;
	updateOrder: () => void;
	cancelAllFuls: () => Promise<boolean>;
	fulOrders: Types.FulfillmentOrder[];
	fulfillWithOptions: (
		fulLineItems: {
			fulfillmentOrderId: string;
			fulfillmentOrderLineItems: { id: string; quantity: number }[];
		}[],
		notify?: boolean,
		printThisOrder?: boolean
	) => void;
	lineItems: Types.LineItem[];
	orderNumber: number;
}

export default function PaymentButtons({
	createOrder,
	transactions,
	setTransactions,
	totalPrice,
	subTotalPrice,
	taxLines,
	orderId,
	fulfillAllOrders,
	fulfilled,
	discountCodes,
	setDiscountCodes,
	setSubtotalPrice,
	setTotalPrice,
	setTaxLines,
	changeAmount,
	setChangeAmount,
	typedValue,
	setTypedValue,
	reprintable,
	clearOrder,
	orderNotes,
	setOrderNotes,
	addNotesToOrder,
	updateOrder,
	cancelAllFuls,
	fulOrders,
	fulfillWithOptions,
	lineItems,
	orderNumber
}: PaymentButtonsProps) {
	const [payWindow, setPayWindow] = useState(false);
	const [typedPaymentValue, setTypedPaymentValue] = useState<number>(0);
	const [convertedPaymentValue, setConvertedPaymentValue] = useState<number>(0);
	const [amountDue, setAmountDue] = useState<number>(0);
	const [refundDue, setRefundDue] = useState(false);
	const paymentRef = useRef<HTMLInputElement>(null);
	const [showChangeModal, setShowChangeModal] = useState(false);
	const [editingOrderNotes, setEditingOrderNotes] = useState(false);
	const [cancellingOrder, setCancellingOrder] = useState(false);
	const [cancelAmount, setCancelAmount] = useState<number | undefined>(undefined);
	const [cancelReason, setCancelReason] = useState<string | undefined>(undefined);
	const [totalPaid, setTotalPaid] = useState<number>(0);
	const [fulOptions, setFulOptions] = useState(false);
	const [fulfilledQuantities, setFulfilledQuantities] = useState({});
	const [notifyCustomer, setNotifyCustomer] = useState(true);
	const [printReceipt, setPrintReceipt] = useState(false);



	useEffect(() => {
		if (!isNaN(Number(typedValue))) {
			setTypedPaymentValue(Number(typedValue));
			//if its has a decimal
			if (typedValue.includes('.')) {
				setConvertedPaymentValue(Number(typedValue));
			} else {
				setConvertedPaymentValue(Number(typedValue) / 100);
			}
		} else (
			setTypedPaymentValue(0),
			setConvertedPaymentValue(0)
		)
	}, [typedValue]);

	useEffect(() => {
		if (payWindow) {
			const timer = setTimeout(() => {
				if (paymentRef.current) {
					paymentRef.current.focus();
				}
			}, 0); // Ensure the focus call is pushed to the end of the event queue
			return () => clearTimeout(timer);
		}
	}, [payWindow]);

	useEffect(() => {
		function isMobileDevice() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			);
		}

		const handleClick = (event) => {
			if (
				event.target.tagName !== "INPUT" &&
				paymentRef.current &&
				document.contains(paymentRef.current) &&
				event.target.closest(".paymentModal") // Check if the click was not within an element with the class "paymentModal"
			) {
				paymentRef.current.focus();
			}
		};

		if (!isMobileDevice()) {
			document.addEventListener("click", handleClick);
			//console.log("not a mobile device!");
		} else {
			//console.log("is mobile.");
		}

		// Cleanup the event listener when the component unmounts
		return () => {
			if (!isMobileDevice()) {
				document.removeEventListener("click", handleClick);
			}
		};
	}, []); // focus on the payment input always when modal

	useEffect(() => {
		const transactionsTotal = transactions.reduce(
			(sum, transaction) =>
				sum +
				(transaction?.kind === "sale" ? transaction.amount || 0 : 0) -
				(transaction?.kind === "refund" ? transaction.amount || 0 : 0) -
				(transaction?.kind === "change" ? transaction.amount || 0 : 0),
			0
		);

		setAmountDue(Number((totalPrice - transactionsTotal).toFixed(2)));
	}, [convertedPaymentValue, totalPrice, amountDue]); //keep amountDue updated

	useEffect(() => {
		//console.log('calculating amount due for change from transactions: ',transactions,'and total price: ',totalPrice)
		const transactionsTotal = Number(transactions.reduce(
			(sum, transaction) =>
				sum +
				(transaction?.kind === "sale" ? transaction.amount || 0 : 0) -
				(transaction?.kind === "refund" ? transaction.amount || 0 : 0) -
				(transaction?.kind === "change" ? transaction.amount || 0 : 0),
			0
		).toFixed(2));
		setTotalPaid(transactionsTotal);
		setCancelAmount(transactionsTotal)
		setAmountDue(Number((totalPrice - transactionsTotal).toFixed(2)));
		//console.log('change amount due: ', amountDue)
		// Check if the transactions total matches totalPrice and totalPrice isn't 0
		console.log('transactions total: ', transactionsTotal, 'total price: ', totalPrice, 'transactions: ', transactions)
		if (transactionsTotal === totalPrice && transactions.length > 0 && !orderId) {
			setPayWindow(false);
			createOrder();
		} else if (transactions.length > 0 && transactionsTotal > totalPrice) {
			handleChange(transactionsTotal - totalPrice);
		}
	}, [transactions]); //after transaction, checks if finished paying or change needed


	const cancelOrder = async (orderId: number, amount?: number, reason?: string) => {



		try {
			await cancelAllFuls();
			const response = await fetch('/api/cancel-order', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
				body: JSON.stringify({
					orderId,
					amount: cancelAmount,
					reason: cancelReason,
					email: true,
					restock: true,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				console.log('Order canceled successfully:', data);
				


				fetch("/api/cancel-attendance", {
					method: "POST",
					headers: {
					  "Content-Type": "application/json",
					  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						order_number: orderNumber
					})
				  })
				  .then((response) => response.json())
				  .then((data) => {
					updateOrder();
					// Handle success - e.g., update UI or state
				  })
				  .catch((error) => {
					updateOrder();
					console.log('failed to cancel attendance:', error);
					// Handle error - e.g., show error message to user
				  });



				// Handle successful cancellation here (e.g., show a success message)
			} else {
				console.error('Error canceling order:', data);
				// Handle error here (e.g., show an error message)
			}
		} catch (error) {
			console.error('Error canceling order:', error);
			// Handle error here (e.g., show an error message)
		}
	};




	const moneyDisplay = (amount: number) => {
		if (amount === 0) return "";
		return `${amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`; // Convert number to money for display with commas
	}; //converts number to money for display

	const toggleModal = () => {
		setPayWindow((prev) => !prev);
	}; //toggles payment modal window

	const handleChange = (amount: number) => {
		const newTransactionsArray = [...transactions];
		let newTransaction: Types.Transaction = {};
		newTransaction = {
			amount: amount,
			gateway: 'Cash',
			currency: "USD",
			kind: "change",
			status: "success",
			message: "Change",
		};
		newTransactionsArray.push(newTransaction);
		setTransactions(newTransactionsArray);
		setTypedPaymentValue(0);
		setConvertedPaymentValue(0);
		setChangeAmount(amount);
		setShowChangeModal(true);
	}; //creates a change payment

	const handleAcknowledgement = () => {
		// Logic after acknowledging the change
		setShowChangeModal(false);
		setEditingOrderNotes(false);
	};

	const handlePayment = (type: string) => {
		const newTransactionsArray = [...transactions];
		let newTransaction: Types.Transaction = {};
		newTransaction = {
			amount: convertedPaymentValue || amountDue,
			gateway: type,
			currency: "USD",
			kind: "sale",
			status: "success",
		};
		console.log('newTransaction', newTransaction)
		newTransactionsArray.push(newTransaction);
		setTransactions(newTransactionsArray);
		setTypedPaymentValue(0);
		setConvertedPaymentValue(0);
		setTypedValue('');
	}; //creates a payment

	const handleDiscount = (type: string) => {
		setPayWindow(false);
		createOrder();
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(e.target.value);
		if (!isNaN(value)) {
			setTypedPaymentValue(value);
			setConvertedPaymentValue(value / 100);
			//console.log("value: ", value);
			//console.log("payment value: ", value / 100);
		}
	}; //input handler, also updates display which converts integer to money amounts 2499 => 24.99

	const handleInputKeydown = (e) => {
		if (e.key === "Enter" || e.keyCode === 13) {
			handlePayment("Cash");
		}
	}; //submit cash payment on Enter press

	const deleteTransaction = (transactionToDelete: Types.Transaction) => {
		const newTransactions = transactions.filter(transaction => transaction !== transactionToDelete);
		setTransactions(newTransactions);
	}

	const [isAddingDiscount, setIsAddingDiscount] = useState(false);

	const toggleAddDiscount = (discount?: Types.DiscountCode) => {
		("toggling edit!");
		if (isAddingDiscount && discount) {
			saveDiscount(discount);
		} else if (isAddingDiscount && !discount) {
			setIsAddingDiscount(false);
			console.log('removing add discount')
		} else {
			setIsAddingDiscount(true);
		}
	};

	const saveDiscount = (discount: Types.DiscountCode) => {
		setDiscountCodes([...discountCodes, discount])
		setIsAddingDiscount(false);
	}

	return (
		<div className="paymentButtonsInnerWrapper">

			{!orderId && totalPrice !== 0 ? (
				<>

					<button className="payBtnHalf" onClick={e => toggleAddDiscount()}>
						Add Discount
					</button>
					<button className="payBtnHalf" onClick={() => handlePayment("Less Deposit")}>
						{moneyDisplay(convertedPaymentValue || amountDue)} Less Deposit
					</button>
					<button className="payBtnHalf" onClick={() => handlePayment("Cash")}>
						{moneyDisplay(convertedPaymentValue || amountDue)} Cash
					</button>
					<button className="payBtnHalf" onClick={() => handlePayment("Quickbooks Payments")}>
						{moneyDisplay(convertedPaymentValue || amountDue)} Card
					</button>
					<button className="payBtnHalf" onClick={() => handlePayment("Tab")}>
						{moneyDisplay(convertedPaymentValue || amountDue)} Tab
					</button>
					<button className="payBtnHalf" onClick={toggleModal}>
						All Payment Options
					</button>
				</>
			) : !orderId && totalPrice === 0 ? (
				<>
					<button className="payBtnHalf" onClick={e => toggleAddDiscount()}>
						Add Discount
					</button>
					<button className="payBtnHalf" onClick={() => handlePayment("Less Deposit")}>
						{moneyDisplay(convertedPaymentValue || amountDue)} Less Deposit
					</button>
					<button className="payBtn" onClick={clearOrder}>
						Clear Order
					</button>
					<button className="payBtn" onClick={createOrder}>
						Submit Order
					</button>
				</>
			) : fulfilled !== 'fulfilled' ? (
				<>
					<button className="payBtnHalf" onClick={() => setEditingOrderNotes(true)}>
						{orderNotes ? 'Edit Order Notes' : 'Add Order Notes'}
					</button>
					<button className="payBtnHalf" onClick={() => setCancellingOrder(true)}>
						Cancel Order
					</button>
					<button className="payBtn" onClick={() => setFulOptions(true)}>
						Fulfillment Options
					</button>
					<button className="payBtn" onClick={() => fulfillAllOrders(true, false)}>
						Fulfill All
					</button>
				</>
			) : (
				<>

					<button className="payBtnHalf" onClick={() => {
						setEditingOrderNotes(true);
					}}>
						{orderNotes ? 'Edit Order Notes' : 'Add Order Notes'}
					</button>
					<button className="payBtnHalf" onClick={() => setCancellingOrder(true)}>
						Cancel Order
					</button>
					<button className="payBtn" onClick={() => {
						clearOrder();
					}}>
						Clear Order
					</button>
					<button className="payBtn" onClick={() => {
						if (window.electronAPI && reprintable) {
							window.electronAPI.reprintLastOrder();
						} else {
							fulfillAllOrders(false, true);
						}

					}}>
						{window.electronAPI && reprintable
							? 'Reprint Receipt'
							: 'Print Receipt'
						}
					</button>
				</>
			)}


			{payWindow && (
				<>
					<div className="paymentModalBackdrop" onClick={toggleModal}></div>
					<div className="paymentModal">
						<table className="receiptTable">
							<tbody>
								<tr>
									<td>Subtotal:</td>
									<td>{moneyDisplay(subTotalPrice)}</td>
								</tr>
								{taxLines.map((line) => (
									<tr key={line.title}>
										<td>
											{line.title} ({line.rate || 0 * 100}%):
										</td>
										<td>{moneyDisplay(line.price || 0)}</td>
									</tr>
								))}
								<tr>
									<td>Total:</td>
									<td>{moneyDisplay(totalPrice)}</td>
								</tr>
								{transactions.map((transaction) => (
									<tr
										key={transaction.gateway}
										className={`transactionRow ${transaction.kind == "sale" ? "redPayment" : ""
											}`}
									>
										<td>{transaction.message || transaction.gateway}:</td>
										<td>
											{moneyDisplay(transaction.amount || 0)}
											<button
												className="deleteTransactionButton"
												onClick={() => deleteTransaction(transaction)}
											>
												[x]
											</button>
										</td>
									</tr>
								))}
								<tr style={{ fontWeight: "bold" }}>
									{amountDue > 0 ? <td>Amount Due:</td> : <td />}
									{amountDue > 0 ? <td>{moneyDisplay(amountDue)}</td> : <td />}

								</tr>
							</tbody>
						</table>
						<br />
						<div className="inputWrapper">
							<span className="displayedValue">
								{moneyDisplay(convertedPaymentValue || amountDue)}
							</span>
							<input
								value={typedPaymentValue}
								ref={paymentRef}
								onChange={handleInputChange}
								onKeyDown={handleInputKeydown}
							/>
						</div>
						<br />
						<button onClick={() => handlePayment("Cash")}>Cash</button>
						<br />
						<button onClick={() => handlePayment("Quickbooks Payments")}>
							Quickbooks Payments
						</button><br />
						<button onClick={() => handlePayment("Less Deposit")}>
							Less Deposit
						</button>
						<br />
						<button onClick={() => handlePayment("Tab")}>
							Tab
						</button>
					</div>
				</>
			)}
			{showChangeModal && (
				<>
					<div className="paymentModalBackdrop" onClick={handleAcknowledgement}></div>
					<div className="paymentModal">
						<div className="modal-content">
							<h2>Change Due</h2>
							<p>Please give the customer <h1>{moneyDisplay(changeAmount)}</h1> in change.</p>
							<button onClick={handleAcknowledgement}>OK</button>
						</div>
					</div>
				</>
			)}
			{isAddingDiscount && (
				<>
					<div className="discountModalBackdrop" onClick={e => toggleAddDiscount()}></div>
					<div key={"discountpanel"} className="editDiscount">
						<b>Add Discount</b> <sup onClick={e => toggleAddDiscount()}>[x]</sup>
						<div className="discountInfo" id="discountinfo">
						</div>
						<button onClick={e => toggleAddDiscount({
							code: "PREMIUM",
							amount: 20,
							type: "percentage"
						})}>Premium Discount</button><br />
						<label htmlFor="custom-discount-amount">Custom $ Discount</label>
						<input
							id="custom-discount-amount"
							type="number"
							onKeyDown={e => {
								if (e.key === "Enter") {
									const input = e.target as HTMLInputElement;
									const val = parseFloat(input.value);
									if (!isNaN(val)) {
										toggleAddDiscount({
											code: `$${val} Off`,
											amount: val,
											type: "fixed_amount"
										});
									}
								}
							}}
						/><br />
						<label htmlFor="custom-discount-percent">Custom % Discount</label>
						<input
							id="custom-discount-percent"
							type="number"
							max="100"
							onKeyDown={e => {
								if (e.key === "Enter") {
									const input = e.target as HTMLInputElement;
									const pval = parseFloat(input.value);
									if (!isNaN(pval) && pval <= 100) {
										toggleAddDiscount({
											code: `${pval}% Off`,
											amount: pval,
											type: "percentage"
										});
									}
								}
							}}
						/><br />
					</div>
				</>
			)}
			{editingOrderNotes && (
				<>
					<div className="paymentModalBackdrop" onClick={() => {
						addNotesToOrder();
						setEditingOrderNotes(false);
					}}></div>
					<div className="paymentModal">
						<div className="modal-content">
							<h2>Order Notes</h2>
							<input
								value={orderNotes}
								className="orderNotes"
								type="textarea"
								onChange={(e) => setOrderNotes(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault(); // Prevent default behavior (like form submission)
										addNotesToOrder();
										setEditingOrderNotes(false);
									}
								}}
							/>
							<button onClick={() => {
								addNotesToOrder();
								setEditingOrderNotes(false);
							}}>OK</button>
						</div>
					</div>
				</>
			)}
			{cancellingOrder && (
				<>
					<div className="paymentModalBackdrop" onClick={() => setCancellingOrder(false)}></div>
					<div className="paymentModal">
						<div className="cancel-modal-content">
							<h2>Cancel Order</h2>
							<label htmlFor="cancel-amount">Amount to Refund:</label>
							<input
								id="cancel-amount"
								type="number"
								max={totalPaid}
								min={0}
								value={cancelAmount}
								onChange={(e) => setCancelAmount(Number(e.target.value))}
							/>
							<label htmlFor="cancel-reason">Reason:</label>
							<input
								id="cancel-reason"
								type="text"
								value={cancelReason}
								onChange={(e) => setCancelReason(e.target.value)}
							/><br /><br />
							<button onClick={() => {
								cancelOrder(orderId, cancelAmount, cancelReason);
								setCancellingOrder(false);
							}}>Cancel Order</button>
						</div>
					</div>
				</>
			)}
			{fulOptions && (
				<>
					<div className="paymentModalBackdrop" onClick={() => setFulOptions(false)}></div>
					<div className="fulOptionsModal">
						<div className="modal-content">
							<h2>Fulfill Order Options</h2>
							{fulOrders
							.filter((ful) => ful.supported_actions.includes("create_fulfillment"))
							.map((order) => (
									<div key={order.id}>
									{order.line_items
									.filter(item=>item.fulfillable_quantity > 0)
									.map((item) => (
										
										<div key={item.id}>
											<label>
												<b>{lineItems.find((i) => i.id === item.line_item_id)?.title}</b>
												<br/>
												<i>{lineItems.find((i) => i.id === item.line_item_id)?.variant_title}</i>
												{lineItems.find((i) => i.id === item.line_item_id)?.variant_title?<br/>:<></>}
												<input
													type="number"
													min="0"
													max={item.fulfillable_quantity}
													defaultValue={item.fulfillable_quantity}
													onChange={(e) => {
														const quantity = parseInt(e.target.value, 10);
														setFulfilledQuantities((prev) => ({
															...prev,
															[item.id]: quantity
														}));
													}}
												/> / {item.fulfillable_quantity} available
											</label>
										</div>
									))}
								</div>
							))}
							<label>
								<input
									type="checkbox"
									defaultChecked={notifyCustomer}
									onChange={(e) => setNotifyCustomer(e.target.checked)}
								/>
								Send Email Notification
							</label>
							<label>
								<input
									type="checkbox"
									defaultChecked={printReceipt}
									onChange={(e) => setPrintReceipt(e.target.checked)}
								/>
								Print Receipt
							</label>
							<br /><br />
							<button onClick={() => {
								const fulfillData = fulOrders
								.filter((ful) => ful.supported_actions.includes("create_fulfillment"))
								.map((order) => ({
									fulfillmentOrderId: `gid://shopify/FulfillmentOrder/${order.id}`,
									fulfillmentOrderLineItems: order.line_items
									.filter((item) => fulfilledQuantities[item.id] > 0 || fulfilledQuantities[item.id] === undefined)
									.map((item) => ({
										id: `gid://shopify/FulfillmentOrderLineItem/${item.id}`,
										quantity: fulfilledQuantities[item.id] || item.fulfillable_quantity
									}))
								}));
								fulfillWithOptions(fulfillData, notifyCustomer, printReceipt);
								setFulOptions(false);
							}}>
								Fulfill Selected
							</button>
						</div>
					</div>
				</>
			)}


		</div>
	);
}
