import addBeep from './add.mp3';
import bloop1 from './bloop1.mp3';
import bloop2 from './bloop2.mp3';
import bloop3 from './bloop3.mp3';
import chaching from './chaching.mp3';
import pop1 from './pop1.mp3';
import pop2 from './pop2.mp3';
import pop3 from './pop3.mp3';
import pop4 from './pop4.mp3';
import pop5 from './pop5.mp3';
import pop6 from './pop6.mp3';
import pop7 from './pop7.mp3';
import pop8 from './pop8.mp3';
import pop9 from './pop9.mp3';
import block1 from './block1.mp3';
import block2 from './block2.mp3';



const sounds = {
  addBeep,
  bloop1,
  bloop2,
  bloop3,
  pop1,
  pop2,
  pop3,
  pop4,
  pop5,
  pop6,
  pop7,
  pop8,
  pop9,
  block1,
  block2,
  chaching
};

export default sounds;
