// indexedDBUtils.js

/**
 * Opens an IndexedDB database.
 */
const openDB = async () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("YourDBName", 1);
  
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      // Create the schema
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("directoryHandles", { keyPath: "id" });
      };
    });
  };
  
  export const storeDirectoryHandle = async (handle) => {
    try {
      const db = await openDB();
      const transaction = db.transaction("directoryHandles", "readwrite");
      const store = transaction.objectStore("directoryHandles");
      store.put({ id: "currentDirectory", handle });
      console.log("Directory handle stored successfully");
    } catch (error) {
      console.error("Error storing directory handle:", error);
    }
  };
  
  export const getDirectoryHandle = async () => {
    try {
      const db = await openDB();
      const transaction = db.transaction("directoryHandles", "readonly");
      const store = transaction.objectStore("directoryHandles");
      const request = store.get("currentDirectory");
  
      return new Promise((resolve, reject) => {
        request.onerror = (event) => {
          console.error("Database error:", event.target.errorCode);
          reject("Database error: " + event.target.errorCode);
        };
  
        request.onsuccess = (event) => {
          const data = event.target.result;
          console.log("Retrieved directory handle:", data);
          resolve(data ? data.handle : null);
        };
      });
    } catch (error) {
      console.error("Error retrieving directory handle:", error);
      return null;
    }
  };