import React from 'react';
import './Numpad.scss'
//import QRScanner from 'qr-code-scanner';
       


export default function Numpad(props){

    const nums = ['1','2','3','4','5','6','7','8','9','.','0','C']
    const numbuttons = [];



    function changeTypedValue(e){
        var num = e.target.id.split('-')[1];
        if(num==="C"){
            props.setTypedValue('')
        }else{
            props.setTypedValue(prevValue=>prevValue+num);
        }
    }



    /*function clearValue(){
        props.setTypedValue('')
    }*/

    for(var i=0;i<nums.length;i++){
        numbuttons.push(
            <p key={'num-'+i} id={'num-'+nums[i]} className="numbutton" onClick={changeTypedValue}>{nums[i]}</p>
        )
    }

    /* function scanner(){
        return QRScanner.initiate({
            onResult: (result) => { alert(result); },
            timeout: 10000,
        });
    } */



    return(
        <div className='numpadInnerWrapper' key='numpadwrapper'>
            <div key='numpad' className='numpad' >
                {numbuttons}
            </div>

        </div>
        

    )
}
