import React, { useState, useEffect } from "react";
import './Checkout.scss';
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';




  

export default function Checkout(props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [completing, setCompleting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();




    const completeOrder = () =>{
        if(!completing && !complete){
            setCompleting(true);
            fetch(`/api/complete-order`,{
                method: 'POST',
                body: JSON.stringify({
                    id: props.id
                }),
                headers: {'Content-type': 'application/json; charset=UTF-8',
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
            .then(res => res.json())
            .then(response => {
                if(response.data.draftOrderComplete.draftOrder){
                    if(props.originalOrderId){
                        fetch(`/api/cancel`,{
                            method: 'POST',
                            body: JSON.stringify({
                                orderId: props.originalOrderId,
                                lineItemId: props.originalLineItemId
                            }),
                            headers: {'Content-type': 'application/json; charset=UTF-8',
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
                        })
                        .then(res => res.json())
                        .then(response => {
                            setComplete(true);
                            toast.success("The party has been edited!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            navigate('/parties');
                        })
                    } else{
                        setComplete(true);
                        toast.success("Reservation Confirmed!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        navigate('/parties');
                    }
                } else {
                    alert(response.data.draftOrderComplete.userErrors[0].message)
                }
                setCompleting(false);
            }) 
        }
    }


    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        window
        .fetch("/create-payment-intent", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                amount: props.depositAmount,
                email: props.email,
                name:props.name,
                phone:props.phone
            })
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setClientSecret(data.clientSecret);
            console.log('stripe customer created!')
        });
    },[props.email,props.depositAmount,props.name,props.phone]);

    const cardStyle = {
        style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: elements.getElement(CardElement)
        }
        });

        if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
        } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        }
    };

    const checkIfSucceeded = () =>{
        if(succeeded){
            completeOrder();
        }
    }

    return (
        <div>
        
        <form className="payment-form" id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <button
            disabled={processing || disabled || succeeded}
            id="submit"
        >
            <span id="button-text">
            {processing ? (
                <div className="spinner" id="spinner"></div>
            ) : (
                "Pay"
            )}
            </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
            <div className="card-error" role="alert">
            {error}
            </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
            
        
            {checkIfSucceeded()}
        </p>
        </form>
        
        </div>
    );
}