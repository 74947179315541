import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import "./Timeslot.scss";

export default function Party(props) {
  const [thisBooking, setThisBooking] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [infoReceived, setInfoReceived] = useState(false);
  const [infoRequested, setInfoRequested] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [depositTotal, setDepositTotal] = useState();
  const [orderTime, setOrderTime] = useState();
  const [additionalItems, setAdditionalItems] = useState([]);
 
  const getPartyInfo = (id) => {
    if (!infoRequested) {
      setInfoRequested(true);
      fetch(`/api/party-info/${id}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((info) => {
          if (info.data && info.data.orders.edges) {
            const orders = info.data.orders.edges;
            for (var k = 0; k < orders.length; k++) {
              const orderItems = orders[k].node.lineItems.edges;
              for (var l = 0; l < orderItems.length; l++) {
                if (
                  orderItems[l].node.sku === id &&
                  orderItems[l].node.refundableQuantity > 0
                ) {
                  setBookingId(orders[k].node.id);
                  const attributes = orderItems[l].node.customAttributes;
                  const contact = {
                    name: orders[k]?.node?.customer?.displayName || "",
                    email: orders[k]?.node?.customer?.email || "",
                    phone:
                      orders[k]?.node?.customer?.phone ||
                      orders[k]?.node?.billingAddress?.phone ||
                      "",
                  };
                  setContactInfo(contact);
                  setThisBooking(attributes);
                  for (var n = 0; n < orderItems.length; n++) {
                    if (n !== l) {
                      var addItems = additionalItems;
                      addItems.push(
                        orderItems[n].node.title +
                          " - $" +
                          parseInt(
                            orderItems[n].node.originalTotalSet.shopMoney.amount
                          ).toFixed(2)
                      );
                      setAdditionalItems(addItems);
                    }
                  }
                  if (additionalItems.length > 0) {
                    var addMoreItems = additionalItems;
                    addMoreItems.push(
                      "Total Paid - $" +
                        parseInt(
                          orders[k].node.totalReceivedSet.shopMoney.amount
                        ).toFixed(2)
                    );
                    setAdditionalItems(addMoreItems);
                  }
                  setDepositTotal(
                    "$" +
                      parseInt(
                        orderItems[l].node.originalTotalSet.shopMoney.amount
                      ).toFixed(2) +
                      " Deposit Paid"
                  );
                  // construct a moment object with UTC-based input
                  var m = moment.utc(orders[k].node.createdAt);
                  // convert using the TZDB identifier for US Central time
                  m.tz("America/Chicago");
                  // format output however you desire
                  var s = m.format("MM/DD/YYYY HH:mm");
                  setOrderTime(s);
                }
              }
            }
          } else {
            console.log("errors: ", JSON.stringify(info.errors[0].message));
            if (info.errors[0].message.includes("Throttled")) {
              setTimeout(function () {
                setInfoRequested(false);
                getPartyInfo(id);
              }, 200);
            }
          }
        })
        .then((done) => {
          //console.log(done);
          setTimeout(function () {
            setInfoReceived(true);
          }, 0);
        });
    }
  };

  const createInfo = (party) => {
    const bookingInfo = [];
    if (party.node.inventoryQuantity <= 0 && bookingId) {
      bookingInfo.push(
        <p className="partyInfoList">
          <b>Contact Name:</b>
          {contactInfo.name}
        </p>
      );
      bookingInfo.push(
        <p className="partyInfoList">
          <b>Contact Phone:</b>
          {contactInfo.phone}
        </p>
      );
      bookingInfo.push(
        <p className="partyInfoList">
          <b>Contact Email:</b>
          {contactInfo.email}
        </p>
      );
      for (var j = 0; j < thisBooking.length; j++) {
        bookingInfo.push(
          <p className="partyInfoList">
            <b>{thisBooking[j].value ? thisBooking[j].key : ""}</b>{" "}
            {thisBooking[j].value}
          </p>
        );
      }
      bookingInfo.push(<br />);
      bookingInfo.push(
        <p className="partyInfoList">
          {depositTotal} on {orderTime}
        </p>
      );
      for (var o = 0; o < additionalItems.length; o++) {
        bookingInfo.push(<p className="partyInfoList">{additionalItems[o]}</p>);
      }
      const url = `/party/?original=${bookingId.split("/")[4]}`;
      bookingInfo.push(
        <Link to={url}>
          <button className="partyButton no-print">Edit</button>
        </Link>
      );
    } else if (party.node.inventoryQuantity <= 0 && !bookingId) {
      bookingInfo.push(<div className="giantX">X</div>);
      //const url = `/party/?sku=${party.node.sku}`;
      bookingInfo.push(<h2 className="">Manually Blocked Off</h2>);
    } else {
      bookingInfo.push(
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      );
      const url = `/party/?sku=${party.node.sku}`;
      bookingInfo.push(
        <Link to={url}>
          <button className="partyButton no-print">Available</button>
        </Link>
      );
    }
    return <div className="bookingInfo">{bookingInfo}</div>;
  };

  const party = props.party;
  var partyTitle = party.node.title;
  var partyTimeCut = parseInt(partyTitle.split("/ ")[1].split(":")[0]);
  //console.log(`partyTimeCut for ${party.node.title}: `, partyTimeCut);
  if (partyTimeCut >= 10) {
    partyTimeCut -= 9;
  } else {
    partyTimeCut += 3;
  }

  if (party.node.inventoryQuantity > 0 || infoReceived) {
    return (
      <div
        style={{
          gridArea: `${
            party.node.title.includes("Carousel") && window.screen.width < 601
              ? partyTimeCut + 100
              : party.node.title.includes("Midway")
              ? partyTimeCut + 200
              : party.node.title.includes("Turning")
              ? partyTimeCut + 300
              : partyTimeCut
          }/${
            party.node.title.includes("Large") || window.screen.width < 601 || party.node.title.includes("Midway")|| party.node.title.includes("Turning")
              ? 1
              : 2
          }
                /span 2/
          ${
            party.node.title.includes("Midway")||party.node.title.includes("Turning")
            ?"span 2"
            :"span 1"
          }`,
          border: "1px outset black",
        }}
      >
        <h5 className="partyTitle">{party.node.title.split(" / ")[1]}</h5>
        {createInfo(party)}
        <br />
      </div>
    );
  } else {
    if (!infoRequested) {
      getPartyInfo(party.node.sku);
    }
    return (
      <div
        style={{
          gridArea: `${
            party.node.title.includes("Carousel") && window.screen.width < 601
              ? partyTimeCut + 100
              : partyTimeCut
          }/${
            party.node.title.includes("Large") || window.screen.width < 601
              ? 1
              : 2
          }/span 2/span 1`,
          border: "1px outset black",
        }}
      >
        <h5 className="partyTitle">{party.node.title.split(" / ")[1]}</h5>
        {infoReceived ? "Blocked Off / Not Available" : "Loading..."}
        <br />
        <br />
      </div>
    );
  }
}
